
import "./AdminLectures.css"
import MainHeader from "../../components/header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropDownInput from "../../components/DropDownInput";
import InputField from "../../components/Input";
import { useEffect, useState } from "react";
import axios from "axios";
import public_variables from "../../publicVar";
import formatDateWithDaysRemaining from "../../functions/NextData";
import extractSrc from "../../functions/ExtractSrc";
import { useSideMessage } from "../../components/Error/SideMessageContext";
import { useLocation } from "react-router";
function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
function AdminLecturesPage() {
    const query = useQuery();
    const activeTrackQuery = query.get("t");  // Extract the query parameter
    
    // Error Message 
    const { showMessage } = useSideMessage();

    // Active Track 
    const [TracksDropList,SetTrachksDropList] = useState([])
    const [TracksDropListPlaceHolder,SetTrachksDropListPlaceHolder] = useState('')
    const [activeTrackID,setActiveTrackID] = useState(null)

    // Lectures List
    const [rawLecturesList,setRawLecturesList] = useState([])
    const [lecturesList,setLecturesList] = useState([])

    // Active Info
    const [activeLectureID,setActiveLectureID] = useState(null)
    const [activeMaterials,setActiveMaterials] = useState([])
    const [activeSuggestedMaterials,setActiveSuggestedMaterials] = useState([])
    const [activeTasks,setActiveTasks] = useState([])

    // update or Rerender Variables
    const [updateMaterials,setUpdateMaterials] = useState(false)
    const [updateTasks,setUpdateTasks] = useState(false)
    const [updateHieght,setUpdateHieght] = useState(false)

    // Form Messages
    const [message,setMessage] = useState(null)
    const [isError,setIsError] = useState(false)

    // Open The Form to Add New Lecture
    function openAddLectureForm(event){
        document.getElementById("AdminLecturesPage")
        .querySelector(".backgroundBlock")
        .style.display = "block"
        document.getElementById("AdminLecturesPage")
        .querySelector(".changeFromContainer")
        .style.zIndex = "999"
        document.getElementById("AdminLecturesPage")
        .querySelector(".ChangingForms").classList.add("shown")

        setMessage(null)
    }

    // Close The Form of Add New Lecture
    function closeAddLectureForm(event){
        document.getElementById("AdminLecturesPage")
        .querySelector(".backgroundBlock")
        .style.display = "none"
        document.getElementById("AdminLecturesPage")
        .querySelector(".ChangingForms").classList.remove("shown")
        document.getElementById("AdminLecturesPage")
        .querySelector(".changeFromContainer")
        .style.zIndex = "-1"
    }

    // Submit The Form to Add New Lecture
    function SubmitNewLectureForm(event){
        const lectureName = document.querySelector("#lectureNameInput input").value  
        const lectureDate = document.querySelector("#lectureDateInput input").value
        console.log("sadasdsa")
        if (lectureDate === ""){
            setMessage("يجب تحديد التاريخ")
            setIsError(true)
            return 
        }

        // Clear the Message
        setMessage(null)
        setIsError(false)

        // Construct Request Data
        const reqData = {
            trackID: activeTrackID,
            lectureTitle: lectureName,
            lectureDate: lectureDate
         }

        // Send to API
        axios.post(public_variables.backend_url+"/admin-lectures/new_lecture",reqData).then(res=>{
            window.location.reload()
        }).catch(err=>{
            console.log("Error!",err)
            showMessage(err.response.data)
        })
    }
    // Ask Are you Sure Before Deleteing
    function AskAreYouSure(event){
        const selected = event.currentTarget
        const sure = event.currentTarget.parentElement.querySelector(".sureButton")
        if (sure.classList.contains("hide")){
            sure.classList.remove("hide")
            selected.classList.add("hide")
        }else{
            sure.classList.add("hide")
            selected.classList.add("hide")

        }
    }

    // Delete Lecture
    function removeLecture(event){
        const lectureID = event.currentTarget.closest(".LectureBox").id.replace("Lecture","")
        const reqData = {
            lectureID:lectureID
        }
        axios.delete(public_variables.backend_url+"/super-admin/lecture",{data:reqData}).then((res)=>{
            window.location.reload()
        }).catch((err)=>{
            console.log("Error!",err)
            showMessage(err.response.data)
        })
    }

    // Set Active Lecture ID to Get it's Materials
    function OpenCloseBox(event){
        const element = event.currentTarget.parentElement.querySelector(".materialsAndTasks")
        const lectureBox = event.currentTarget.parentElement
        const openElements = document.querySelectorAll("#AdminLecturesPage .lectureBoxesContainer .open")
         

        // Check The Current Selection Before Close the Rest
        const checkCurrent = lectureBox.classList.contains("open")
        if(openElements.length > 0){
            for (var i of openElements){
                i.classList.remove("open")
                i.classList.add("close")
                var matAndTask = i.querySelector(".materialsAndTasks")
                if (matAndTask){
                    matAndTask.style.height ="0px"
                }

            }
        }
        if (checkCurrent){
            
            lectureBox.classList.remove("open")
            lectureBox.classList.add("close")
            element.style.height ="0px"
            setActiveMaterials([])
            setActiveTasks([])

        }else{
            setActiveLectureID(lectureBox.id.replace("Lecture",''))
            lectureBox.classList.remove("close")
            lectureBox.classList.add("open")
            element.style.height = element.scrollHeight +"px"
        }
    }

    // Show and Hide Add New Mini Forms for adding New Materials and Tasks
    function showHideAddNewList(event){
        const newForm =  event.currentTarget.parentElement.querySelector(".addNewForm")
        if (newForm.classList.contains("open")){
            newForm.style.height = "0px"
            event.currentTarget.closest(".LectureBox").querySelector(".materialsAndTasks").style.height =
            Number(event.currentTarget.closest(".LectureBox").querySelector(".materialsAndTasks").style.height.replace("px","")) -  newForm.scrollHeight +"px"
            newForm.classList.remove("open")
        }else{
            newForm.style.height = newForm.scrollHeight +"px"
            event.currentTarget.closest(".LectureBox").querySelector(".materialsAndTasks").style.height =
            Number(event.currentTarget.closest(".LectureBox").querySelector(".materialsAndTasks").style.height.replace("px","")) +  newForm.scrollHeight +"px"
            newForm.classList.add("open")
        }

    }

    // Adding New Material
    function addNewMaterial(event){
        const form = event.currentTarget.parentElement
        const lectureId = event.currentTarget.parentElement.closest(".LectureBox").id.replace("Lecture",'')
        const MaterialSuggesttion = form.querySelector(".suggestionSelect").value
        const MaterialType = form.querySelector(".materialTypeSelect").value
        const MaterialName = form.querySelector(".NewMaterialName input").value
        var  MaterialLink = form.querySelector(".NewMaterialLink input").value

        // Validate the Inputs
        if (MaterialName === ""){
            return
        }

        // Extract the Link from IFrame If Video
        if (MaterialType === "video"){
            MaterialLink = extractSrc(MaterialLink)
            if(!MaterialLink){
                showMessage("الرابط يجب ان يكون داخل وسم <iframe>")
                return
            }
        }
        const reqData = {
            lectureID: lectureId,
            materialTitle: MaterialName,
            materialVideo: MaterialType === "video",
            materialLink: MaterialLink,
            suggestion:MaterialSuggesttion ==='suggestion'
         }
        // Save to API
        axios.post(public_variables.backend_url+"/admin-lectures/new_material",reqData).then((res)=>{
            const element = <div id={"mat"+res.data["materialID"]} className={"materialBox "+ (MaterialType ==="video"?"video":"file")}>
                                <div className="row">
                                    {MaterialType ==="video"?
                                        <FontAwesomeIcon icon="fa-solid fa-circle-play" />
                                        :
                                        (!MaterialLink || MaterialLink ==="")?
                                            <FontAwesomeIcon icon="fa-solid fa-file-lines" />
                                            :
                                            <FontAwesomeIcon icon="fa-solid fa-link" />
                                    }
                                    {
                                    (!MaterialLink || MaterialLink === ""  || MaterialType ==="video")?
                                        <span >{MaterialName}</span>
                                        :
                                        <a target="__blank" href={MaterialLink}><span >{MaterialName}</span></a>
                                    }
                                    <button className="deleteButton" title="حذف المادة" onClick={removeMaterial}><FontAwesomeIcon icon="fa-solid fa-trash" /></button>

                                </div>

                                {
                                MaterialType ==="video"? 
                                <div className="videoContainer">
                                        <iframe width="560" height="315" src={MaterialLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="true"></iframe>
                                    </div>
                                :
                                null
                                }
                            </div>
                    if(MaterialSuggesttion ==='suggestion'){
                        setActiveSuggestedMaterials([[...activeSuggestedMaterials,element]])
                    }else{
                        setActiveMaterials([...activeMaterials,element])

                    }
                    setUpdateMaterials(!updateMaterials)

            // Empty the Inputs
            form.querySelector(".NewMaterialName input").value = ''
            form.querySelector(".NewMaterialLink input").value = ''
        }).catch((err)=>{
            console.log("Error!!",err)
            showMessage(err.response.data)
        })

        
    }

    // Delete Material
    function removeMaterial(event){
        const materialBox = event.currentTarget.closest(".materialBox")
        const elementID = materialBox.id.replace("mat","")
        const reqData = {
            materialID:elementID
        }  
        // send To API
        axios.delete(public_variables.backend_url+"/admin-lectures/material",{data:reqData}).then((res)=>{
            materialBox.classList.add("hide")

        }).catch(err=>{
            console.log("Error!",err)
            showMessage(err.response.data)
        })
    }

    // Adding New Task
    function addNewTask(event){
        const form = event.currentTarget.parentElement
        const lectureId = event.currentTarget.parentElement.closest(".LectureBox").id.replace("Lecture",'')
        const taskName = form.querySelector(".NewTaskName input").value

        // Validate the Inputs
        if (taskName === ""){
            return
        }

        const reqData = {
            lectureID: lectureId,
            taskText: taskName

        }

        // Save to API
        axios.post(public_variables.backend_url+"/admin-lectures/new_task",reqData).then((res)=>{
            const element = <div id={"task"+res.data['taskID']} className={"taskBox"}  onClick={markTodo}>
                                <div className="BoxIcon"></div>
                                <FontAwesomeIcon icon="fa-solid fa-square-check" /> 
                                <span>{taskName}</span>
                                <button className="deleteButton" title="حذف التكليف" onClick={removeTask}><FontAwesomeIcon icon="fa-solid fa-trash" /></button>
                        </div>
                    setActiveTasks([...activeTasks,element])
                    setUpdateTasks(!updateTasks)

            // Empty the Inputs
            form.querySelector(".NewTaskName input").value = ''
        }).catch((err)=>{
            console.log("Error!!",err)
            showMessage(err.response.data)
        })
    }
    
    // Delete Task
    function removeTask(event){
        const taskBox = event.currentTarget.closest(".taskBox")
        const elementID = taskBox.id.replace("task","")
        const reqData = {
            taskID:elementID
        }  

        // send To API
        axios.delete(public_variables.backend_url+"/admin-lectures/task",{data:reqData}).then((res)=>{
            taskBox.classList.add("hide")

        }).catch(err=>{
            console.log("Error!",err)
            showMessage(err.response.data)
        })
    }

    // Mark Todo Task as Done or Not (Just a View)
    function markTodo(event){
        if (event.currentTarget.classList.contains("done")){
            event.currentTarget.classList.remove("done")
        }else{
            event.currentTarget.classList.add("done")
        }
        
    }

    // Choose Track as Active
    function ChooseActiveTrack(event){
        setActiveTrackID(event.currentTarget.id)
        window.history.pushState(null, '', `/lectures?t=${event.currentTarget.id}`);
    }

    // Get Track ID's
    useEffect(()=>{
        axios.get(public_variables.backend_url+"/admin-tracks/assigned_tracks?adminID="+localStorage.getItem("id")).then((res)=>{

            // SetTrachksDropList(res.data[0]['name'])
            setActiveTrackID(res.data[0]["id"])
            SetTrachksDropList(res.data)
            const filt = res.data.filter(tr =>{return Number(tr['id']) === Number(activeTrackQuery)})
            if(filt.length){
                setActiveTrackID(filt[0]['id'])
                SetTrachksDropListPlaceHolder(filt[0]['name'])
            }else{
                SetTrachksDropListPlaceHolder(res.data[0]['name'])
            }

        }).catch((err)=>{
            console.log("Error:",err)
            showMessage(err.response.data)
        })
    },[])

    // Get The Lectures with Each one's Data From API
    useEffect(()=>{
        if (activeTrackID){
            axios.get(public_variables.backend_url+`/admin-lectures/lectures?trackID=${activeTrackID}&userID=${localStorage.getItem("id")}`)
            .then((res)=>{
                const data = res.data
                // Save the Data From the API
                setRawLecturesList(data)
            }).catch((err)=>{
                console.log("Error:",err)
                showMessage(err.response.data)
        })
        }
    },[activeTrackID])

    // Get Active Lecture's Materials and Tasks
    useEffect(()=>{
        // Check if There is Active
        if (!activeLectureID){
            return;
        }
        
        // Get the Materials
        axios.get(public_variables.backend_url+`/admin-lectures/lecture_materials?lectureID=${activeLectureID}`).then((res)=>{
            const data = res.data
            const temp = [] 
            const suggestionTemp =[]
            // Add Materials
            for(var i = 0;i<data.length;i++){
                const elmnt = <div id={"mat"+data[i]['id']} className={"materialBox "+ (data[i]['video']?"video":"file")}>
                                    <div className="row">
                                        {data[i]['video']?
                                            <FontAwesomeIcon icon="fa-solid fa-circle-play" />
                                            :
                                            (!data[i]["link"] || data[i]["link"] ==="")?
                                                <FontAwesomeIcon icon="fa-solid fa-file-lines" />
                                                :
                                                <FontAwesomeIcon icon="fa-solid fa-link" />
                                        }
                                        {
                                        (!data[i]["link"] || data[i]["link"] === ""  || data[i]['video'])?
                                            <span >{data[i]['title']}</span>
                                            :
                                            <a target="__blank" href={data[i]['link']}><span >{data[i]['title']}</span></a>
                                        }
                                        <button className="deleteButton" title="حذف المادة" onClick={removeMaterial}><FontAwesomeIcon icon="fa-solid fa-trash" /></button>

                                    </div>

                                    {
                                    data[i]['video']? 
                                    <div className="videoContainer">
                                            <iframe width="560" height="315" src={data[i]['link']} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="true"></iframe>
                                        </div>
                                    :
                                    null
                                    }
                                </div>
                if (data[i]['suggestion']){
                    suggestionTemp.push(elmnt)
                }else{
                    temp.push(elmnt)
                }
            } 

            setActiveMaterials(temp)
            setActiveSuggestedMaterials(suggestionTemp)
            setUpdateMaterials(!updateMaterials)
        }).catch((err)=>{
            console.log("Error!!",err)
            showMessage(err.response.data)
        })

        // Get the Tasks
        axios.get(public_variables.backend_url+`/admin-lectures/lecture_tasks?lectureID=${activeLectureID}`).then((res)=>{
            const data = res.data

            const temp = [] 
            // Add Tasks
            for(var i = 0;i<data.length;i++){
                temp.push(
                    <div id={"task"+data[i]["id"]} className={"taskBox "+ (data[i]["done"]?"done":"")}  onClick={markTodo}>
                        <div className="BoxIcon"></div>
                        <FontAwesomeIcon icon="fa-solid fa-square-check" /> 
                        <span>{data[i]["title"]}</span>
                        <button className="deleteButton" title="حذف التكليف" onClick={removeTask}><FontAwesomeIcon icon="fa-solid fa-trash" /></button>

                    </div>
                )
            } 
            setActiveTasks(temp)
            setUpdateTasks(!updateTasks)
        }).catch((err)=>{
            console.log("Error!!",err)
            showMessage(err.response.data)
        })

    },[activeLectureID])
    
    // Build the Lectures HTML and Render the Data
    useEffect(()=>{
        const temp = rawLecturesList.map((lect)=>{
                return  <div key={lect['id']} id={"Lecture"+lect['id']} className="LectureBox close">
                            <div className="header"  onClick={OpenCloseBox}>
                                <div className="headerInfo">
                                    <h2>{lect['title']}</h2>
                                    <span className="LectureDate">
                                        بتاريخ { formatDateWithDaysRemaining(lect['date']).miniformattedDate}   
                                        {localStorage.getItem("role") ==="super-admin" ? <button className="deleteButton" title="حذف المحاضرة" onClick={AskAreYouSure}><FontAwesomeIcon icon="fa-solid fa-trash" /></button>:''}
                                        <button className="deleteButton sureButton hide" title="حذف المحاضرة" onClick={removeLecture}>هل انت متأكد؟</button>
                                    </span>
                                    
                                </div>
                                <div className="arrowIcon">
                                        <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
                                </div>
                            </div>
                            
                            <div className="materialsAndTasks">
                                <h3 className="materialsTitle">المواد العلمية: </h3>
                                <div id={"material" + lect['id']} className="materials">
                                    <div className="materialsBoxs">
                                        {activeMaterials}
                                    </div>
                                    <h3>الترشيحات</h3>
                                    <div className="materialsBoxs">
                                        {activeSuggestedMaterials}
                                    </div>
                                    <div className="addNewForm">
                                        <select className="suggestionSelect">
                                            <option value={"material"}>مادة</option>
                                            <option value={"suggestion"}>ترشيح</option>
                                        </select>
                                        <select className="materialTypeSelect">
                                            <option value={"document"}>رابط</option>
                                            <option value={"video"}>فيديو</option>
                                        </select>
                                        <InputField type={"text"} name={"materialName"+lect['id']} placeholder={"عنوان المادة"} className={"addNewInput NewMaterialName"}/>
                                        <InputField type={"text"} name={"materialLink"+lect['id']} placeholder={"رابط المادة"} className={"addNewInput NewMaterialLink"}/>
                                        <button onClick={addNewMaterial}>أضف</button>
                                    </div>
                                    <button className="AddNewButtons" onClick={showHideAddNewList}><FontAwesomeIcon icon="fa-solid fa-plus" /> أضف مادة جديدة</button>
                                </div>
                                <h3 className="taskTitle">المهام:</h3>
                                <div id={"tasks" + lect['id']} className="tasks">
                                    {activeTasks}
                                    <div className="addNewForm">
                                        
                                        <InputField type={"text"} name={"taskName"+lect['id']} placeholder={"عنوان التكليف"} className={"addNewInput NewTaskName"}/>
                                        <button onClick={addNewTask}>أضف</button>
                                    </div>
                                    <button className="AddNewButtons" onClick={showHideAddNewList}><FontAwesomeIcon icon="fa-solid fa-plus" /> أضف تكليف جديد</button>

                                </div>
                            </div>
                    </div>
        })
        setLecturesList(temp)
        setUpdateHieght(!updateHieght)
    },[rawLecturesList,updateMaterials,updateTasks])

    // Update Opened Lecture Height
    useEffect(()=>{
        if (!activeLectureID){
            return 
        }
        const lectureBox = document.querySelector(`#Lecture${activeLectureID}.open`)
        if(!lectureBox){
            return
        }

        const element = lectureBox.querySelector(".materialsAndTasks")
        element.style.height = element.scrollHeight +"px"
        
    },[updateHieght])

    return (
        <div id="AdminLecturesPage">
            <MainHeader title={"المحاضرات"} comment={""}/>
            <div className="LecturesContainer">
                <div className="containerHeader">
                    <div className="left">
                        <h3 className="ConatinerTitle">{["المحاضرات"]}</h3>

                    </div>
                    <div className="SelectDropdownContainer">
                        <span>أختر مسار: </span>
                        
                        {TracksDropList.length>1?<DropDownInput key ={1} placeholder={TracksDropListPlaceHolder}>
                                                    {TracksDropList.map(TrackOption=>{
                                                        return <option  onClick={ChooseActiveTrack} id={TrackOption['id']}>{TrackOption['name']}</option>
                                                    })}
                                                 </DropDownInput>
                        :TracksDropList.length === 1?TracksDropList[0]['name']:""}

                        <button disabled={activeTrackID?false:true} title="اضف محاضرة" className="addLectureButton" onClick={openAddLectureForm}>+</button>

                    </div>
                </div>
                <div className="lectureBoxesContainer">
                    {lecturesList.length?lecturesList:<p className="grayComment">لا توجد محاضرات بعد</p>}
                </div>
                
                <div className="changeFromContainer">
                    <div className="changeFromContainer">
                        <div className="backgroundBlock" onClick={closeAddLectureForm}></div>
                        <div className="ChangingForms">
                            <h2>اضف محاضرة جديدة</h2> 
                            <span className={isError?"errorMessage":"correctMessage"}>{message}</span>
                            <InputField type={"text"}   id={"lectureNameInput"}  className={"chageFormInput"} name={"TrackName"} placeholder={"اسم المحاضرة"}/>
                            <span className="inputComment">اترك الاسم فارغ للتسمية التلقائية</span>
                            <InputField type={"date"}   id={"lectureDateInput"}  className={"chageFormInput"} name={"TrackDeadline"} placeholder={"تاريخ المحاضرة"}/>
                            <button onClick={SubmitNewLectureForm}>اضف</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminLecturesPage;
