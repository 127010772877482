function formatDateWithDaysRemaining(dateString) {
    if(dateString.length && dateString[dateString.length-1] === 'Z'){
        dateString = dateString.slice(0,dateString.length-1)
    }
    const date = new Date(dateString);
    // Days of the week in Arabic
    // const daysOfWeek = ['الأحد', 'الاثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'];
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const daysOfWeekArabic = {'Sunday':"الأحد", 'Monday':"الاثنين", 'Tuesday':"الثلاثاء", 'Wednesday':"الأربعاء", 'Thursday':"الخميس", 'Friday':"الجمعة", 'Saturday':"السبت"};

    // Get current date
    const now = new Date();

    // Calculate the number of days remaining
    const timeDiff = date.getTime() - now.getTime();
    const daysRemaining = Math.ceil(timeDiff / (1000 * 3600 * 24));

    // Format date parts
    const dayOfWeek = daysOfWeekArabic[daysOfWeek[date.getDay()]];
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    // Construct formatted date
    const formattedDate = `${dayOfWeek}, ${day} - ${month} في ${formattedHours}:${minutes}${period}`;

    const miniformattedDate = `${day} - ${month}`;

    return {
        formattedDate,
        miniformattedDate,
        daysRemaining
    };
}
export default formatDateWithDaysRemaining;
