
import "./AdminAttendance.css"
import MainHeader from "../../components/header";
import Counter from "../../components/Counter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropDownInput from "../../components/DropDownInput";
import { useEffect, useState } from "react";
import axios from "axios";
import public_variables from "../../publicVar";
import { useSideMessage } from "../../components/Error/SideMessageContext";
function AdminAttendancePage() {
    // Error Message 
    const { showMessage } = useSideMessage();
    const lang = localStorage.getItem("lang")
    const pageText = {
        "title":        lang === "english" ? "Attendance": "الحضور",
        "present":      lang === "english" ? "Present": "حاضر",
        "Late":         lang === "english" ? "Late": "متاخر",
        "Absent":       lang === "english" ? "Absent": "غائب",
        "Excuse":       lang === "english" ? "Excuse": "عذر",
        "minipresent":  lang === "english" ? "P": "ح",
        "miniLate":     lang === "english" ? "L": "م",
        "miniAbsent":   lang === "english" ? "A": "غ",
        "miniExcuse":   lang === "english" ? "E": "ذ",
        "Times":        lang === "english" ? "People": "افراد",
        "LectureName":  lang === "english" ? "Lecture Name": "اسم المحاضرة",
        "Date":         lang === "english" ? "Date": "التاريخ",
        "Status":       lang === "english" ? "Status": "الحالة",
        "selectLec":    lang === "english" ? "Select a Lecture": "أختر محاضرة",
        "selectTrack": lang === "english" ? "Select a Track": "أختر مسار",
        "submit":       lang === "english" ? "Submit": "تسليم",
        "edit":       lang === "english" ? "Edit": "تعديل",
        
    }
    function activateStat(event){

        if ( event.currentTarget.classList.contains("active")){
            event.currentTarget.classList.remove("active")
        }else{
            const activeChoise = event.currentTarget.parentElement.querySelector(".active")

            if(activeChoise){
                activeChoise.classList.remove("active")
            }
            event.currentTarget.classList.add("active")
        }
    }

    // Active Track 
    const [TracksDropList,SetTrachksDropList] = useState(" - ")
    const [activeTrackID,setActiveTrackID] = useState(null)
    const [activeTrackName,setActiveTrackName] = useState(null)

    // Active Lecture
    const [LecturesDropList,SetLecturesDropList] = useState(<DropDownInput key ={1} placeholder={"اختر المحاضرة"} disabled={true}></DropDownInput>)
    const [activeLectureID,setActiveLectureID] = useState(null)

    // Status Counts Numbers
    var [presentCount,setPresentCount] = useState(0)
    var [absentCount,setAbsentCount] = useState(0)
    var [lateCount,setLateCount] = useState(0)
    var [excuseCount,setExcuseCount] = useState(0)


    // Attendance Sheet and Names
    const [attendanceSheet,SetAttendanceSheet] = useState([])
    const [correctMessage,setCorrectMessage] = useState(null)
    const [errorMessage,setErrorMessage] = useState(null)

    // Choose Track Actions
    function ChooseTrack(event){
        setActiveTrackID(event.currentTarget.id);
        setActiveTrackName(event.currentTarget.innerHTML)
        SetAttendanceSheet(null);
        setActiveLectureID(null)
    }

    // Download the Sheet 
    function DownloadSheet(event){
        if (!activeTrackID || !activeTrackName){
            return
        }
        axios({
            url: public_variables.backend_url + `/files/track_attendance_sheet?trackID=${activeTrackID}`,
            method: 'GET',
            responseType: 'blob', // Important
        }).then(res=>{
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `ملف الحضور لمسار ${activeTrackName}.xlsx`); // Set the file name here
            document.body.appendChild(link);
            link.click();
        }).catch(err=>{
            console.log("Error!",err)
            showMessage(err.response.data)
        })
    }

    // Get Track ID's
    useEffect(()=>{
        axios.get(public_variables.backend_url+"/admin-tracks/assigned_tracks?adminID="+localStorage.getItem("id")).then((res)=>{

            const options = []
            for(var i=0;i<  res.data.length;i++){
                options.push(<option  onClick={ChooseTrack} id={res.data[i]['id']}>{res.data[i]['name']}</option>)
            }
            SetTrachksDropList(<DropDownInput key ={1} placeholder={"اختر المسار"}>
                                    {options}
                                </DropDownInput>)

        }).catch((err)=>{
            console.log("Error:",err)
            showMessage(err.response.data)
        })
    },[])

    // Get Lectures ID's
    useEffect(()=>{
        if(activeTrackID){
            axios.get(public_variables.backend_url+"/admin-lectures/lectures?trackID="+activeTrackID).then((res)=>{

                const options = []
                for(var i=0;i<  res.data.length;i++){
                    options.push(<option  onClick={(event) => {setActiveLectureID(event.currentTarget.id);setErrorMessage(null)}} id={res.data[i]['id']}>{res.data[i]['title']}</option>)
                }
                SetLecturesDropList(<DropDownInput key ={"track"+activeTrackID} placeholder={"اختر المحاضرة"}>
                                        {options}
                                    </DropDownInput>)
                setErrorMessage(null)
            }).catch((err)=>{
                console.log("Error:",err)
                showMessage(err.response.data)
            })
        }

    },[activeTrackID])

    // Get Attendance Sheet
    useEffect(()=>{
        if(activeTrackID && activeLectureID){
            absentCount=0
            presentCount=0
            lateCount=0
            excuseCount=0
            axios.get(public_variables.backend_url+`/admin-attendance/attendance_sheet?lectureID=${activeLectureID}&trackID=${activeTrackID}`).then((res)=>{
                console.log(res.data)
                const data = res.data
                const temp = []
                // Remove all Previous Active
                const ActiveBoxes = document.querySelectorAll(".stateBox.active")
                console.log(ActiveBoxes)
                for (var i of ActiveBoxes){
                    i.classList.remove("active")
                }

                // Addin New Students
                for (var i = 0;i<data.length;i++){
                    if (data[i]['status'] ==="Absent"){
                        absentCount+=1
                    }else if (data[i]['status'] === "Attended"){
                        presentCount+=1
                    }else if (data[i]['status'] === "Late"){
                        lateCount+=1
                    }else if (data[i]['status'] === "Excuse"){
                        excuseCount+=1
                    }

                    temp.push(
                        <tr className="StudentAttendanceCard" id={"student"+data[i]["studentID"]}>
                            <td className="studentName">{data[i]['studentName']}</td>
                            <td className="statButtons">
                                <div className={"stateBox statusPresent "+(data[i]['status'] === "Attended" ? "active":"")} onClick={activateStat}>
                                    <span className="bigButtons">{pageText['present']}</span>
                                    <span className="smallButtons">{pageText['minipresent']}</span>
                                    <span className="choiseType">Attended</span>
                                </div>
                                <div className={"stateBox statusLate "+(data[i]['status'] === "Late" ? "active":"")} onClick={activateStat}>
                                    <span className="bigButtons">{pageText['Late']}</span>
                                    <span className="smallButtons">{pageText['miniLate']}</span>
                                    <span className="choiseType">Late</span>
                                </div>
                                <div className={"stateBox statusAbsent "+(data[i]['status'] === "Absent" ? "active":"")} onClick={activateStat}>
                                    <span className="bigButtons">{pageText['Absent']}</span>
                                    <span className="smallButtons">{pageText['miniAbsent']}</span>
                                    <span className="choiseType">Absent</span>
                                </div>
                                <div className={"stateBox statusExcuse "+(data[i]['status'] === "Excuse" ? "active":"")} onClick={activateStat}>
                                    <span className="bigButtons">{pageText['Excuse']}</span>
                                    <span className="smallButtons">{pageText['miniExcuse']}</span>
                                    <span className="choiseType">Excuse</span>
                                </div>
                            </td>
                        </tr>
                    )
                }
                SetAttendanceSheet(temp)
                setPresentCount(presentCount)
                setAbsentCount(absentCount)
                setLateCount(lateCount)
                setExcuseCount(excuseCount)
            }).catch((err)=>{
                console.log("Error:",err)
                showMessage(err.response.data)
            })
        }
    },[activeTrackID,activeLectureID])

    function SubmitSheet(event){
        if (!activeTrackID || !activeLectureID){
            setCorrectMessage(null)
            setErrorMessage("يجب اختيار مسار و محاضرة")
            return ;
        }
        const allCards = document.querySelectorAll(".StudentAttendanceCard")
        const Students = []
        for(var i =0;i<allCards.length;i++){

            // Get Student's id and Status
            var id = allCards[i].id.replace("student","")
            var activeStatus = allCards[i].querySelector(".active .choiseType")

            // Adding to the List
            Students.push({
                studentID: id,
                status: activeStatus.innerHTML
             })
        }

        // Constructing The Request
        const reqData = {
            trackID:activeTrackID,
            lectureID:activeLectureID,
            people:Students
        }
        console.log(reqData)
        axios.post(public_variables.backend_url+"/admin-attendance/attendance_sheet",reqData).then((res)=>{
            console.log(res.data)
            setCorrectMessage("تم تحفظ التغيرات بنجاح")
        }).catch((err)=>{
            console.log("Error!",err)
            showMessage(err.response.data)
        })
    }

    return (
        <div id="AdminAttendancePage">
            <MainHeader title={pageText['title']} comment={""}/>
            <div className="DonwloadButtonContainer">
                <button disabled={activeTrackID?false:true} title="تحميل ملف الغياب للمسار المختار" className="DonwloadSheet" onClick={DownloadSheet}>تحميل الملف <FontAwesomeIcon icon="fa-solid fa-file-arrow-down" /></button>
            </div>
            <div className="AttendanceContainer">
                <div className="selectSection">
                    {TracksDropList}
                    {LecturesDropList}
                </div>
                <div className="NumbersBoxes">
                    <div className="NumbersBox NumberBox1">
                        <div className="icon">
                            <FontAwesomeIcon icon="fa-solid fa-clock" />
                            <p className="iconChar">{pageText['minipresent']}</p>
                        </div>
                        <h2>{pageText['present']}</h2>
                        <span><Counter value={presentCount} speed={5000}/> &nbsp;{pageText['Times']}</span>
                    </div>
                    <div className="NumbersBox NumberBox2">
                        <div className="icon">
                            <FontAwesomeIcon icon="fa-solid fa-clock" />
                            <p className="iconChar">{pageText['miniLate']}</p>
                        </div>
                        <h2>{pageText['Late']}</h2>
                        <span><Counter value={lateCount} speed={5000}/> &nbsp;{pageText['Times']}</span>
                    </div>
                    <div className="NumbersBox NumberBox3">
                        <div className="icon">
                            <FontAwesomeIcon icon="fa-solid fa-clock" />
                            <p className="iconChar">{pageText['miniAbsent']}</p>
                        </div>
                        <h2>{pageText['Absent']}</h2>
                        <span><Counter value={absentCount} speed={5000}/> &nbsp;{pageText['Times']}</span>
                    </div>
                    <div className="NumbersBox NumberBox4">
                        <div className="icon">
                            <FontAwesomeIcon icon="fa-solid fa-clock" />
                            <p className="iconChar">{pageText['miniExcuse']}</p>
                        </div>
                        <h2>{pageText['Excuse']}</h2>
                        <span><Counter value={excuseCount} speed={5000}/> &nbsp;{pageText['Times']}</span>
                    </div>
                </div>
                <table>
                    <tr>
                        {/* <th>Lecture Name</th>
                        <th>Status</th> */}
                    </tr>
                    {attendanceSheet}
                    
                </table>
                <button className="listSubmitButton" onClick={SubmitSheet}>{pageText['submit']}</button>
                <span className="correctMessage">{correctMessage}</span>
                <span className="errorMessage">{errorMessage}</span>
            </div>
        </div>
    );
}

export default AdminAttendancePage;
