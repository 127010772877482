
import "./AdminTracks.css"
import MainHeader from "../../components/header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputField from "../../components/Input";
import { useEffect, useState } from "react";
import axios from "../../functions/axiosAuth";
import public_variables from "../../publicVar";
import TrackBox from "../../components/TrackBox";
import { useSideMessage } from "../../components/Error/SideMessageContext";
function AdminTracksPage() {
    // Error Message 
    const { showMessage } = useSideMessage();

    const lang = localStorage.getItem("lang")
    const pageText = {
        "title":      lang === "english" ? "Tracks":"المسارات",
        "addNew":     lang === "english" ? "Add New Track":"أضف مسار جديد",
        "assinged":   lang === "english" ? "Assigned":"المتاحة",
        "enroll":     lang === "english" ? "Enroll":"تسجيل",
        "openForReg": lang === "english" ? "Open For Registration":"متاح للتسجيل",
        "firstLec":   lang === "english" ? "First Lecture at":"اول محاضرة في",
        "edit":       lang === "english" ? "Edit":"تعديل",
        "TrackName": lang === "english" ? "Track Name":"اسم المسار",
        "TrackDesc": lang === "english" ? "Track Description":"وصف المسار",
        "lectureNum": lang === "english" ? "Number of Lectures":"عدد المحاضرات",
    }

    // Assinged List
    const [assignedTracks,setAssignedTracks] = useState()

    // Get Assinged Track
    useEffect(()=>{
        axios.get(public_variables.backend_url+`/admin-tracks/assigned_tracks?adminID=${localStorage.getItem("id")}`)
        .then((res)=>{
            const data = res.data
            const temp = []
            for(var i = 0;i<data.length;i++){
                temp.push(
                    <TrackBox
                    id={data[i]['id']}
                    title={data[i]['name']}
                    description={data[i]['description']}
                    imgLink={data[i]['imageLink']}
                    lectureCount={data[i]['lecturesCount']}
                    />
                )

            }
            // Add Empty Message
            if(!temp.length){
                setAssignedTracks(<span className="emptyMessage">لا توجد مسارات</span>)
            }else{
                setAssignedTracks(temp)
            }

        }).catch((err)=>{
            console.log("Error!",err)
            showMessage(err.response.data)
        })
    },[])

    return (
        <div id="AdminTracksPage">
            <MainHeader title={pageText['title']} comment={""}/>
            <div className="TracksContainer">
                <h3 className="sectionTitle">{pageText['assinged']}</h3>
                <div className="BoxesContainer">
                    {assignedTracks}
                </div>
            </div>
        </div>  
    );
}

export default AdminTracksPage;
