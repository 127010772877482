import axios from "axios";



// Add a request interceptor
axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token")
    const role = localStorage.getItem("role")
    config.headers.Authorization = token
    config.headers.role = role
      
     
    return config;
});

axios.interceptors.response.use(
    (response) => {
      if(!response){
        throw new Error("Hello")
      }
      return response;
    },
    (error) => {
      if (error.response !== undefined){


        if (error.response.status === 401) {
          // Clear the Localstorage
          localStorage.clear()
          
          // Redirect to the login page when a 401 error is encountered
          if (window.location.pathname !== '/login'){
              window.location.href = '/login'
          }else{
              console.log("Already on Login")
          }
          
        }
        if(!document.getElementById("ServerDownPage").classList.contains("hide")){
          document.getElementById("ServerDownPage").classList.add("hide")
        }
        return  Promise.reject(error); // Ensure the error is still rejected
      }else{
        console.log("Didn't Catch Any Response")
        document.getElementById("ServerDownPage").classList.remove("hide")
        // return {data:"There is Error Connecting",response:{data:"There is Error Connecting"}}
        return Promise.reject({data:"There is Error Connecting",response:{data:"There is Error Connecting"}});
      }
       
    }
  );

export default axios