
import "./Financials.css"
import MainHeader from "../../components/header";
import Counter from "../../components/Counter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropDownInput from "../../components/DropDownInput";
import InputField from "../../components/Input";
import { useEffect, useState } from "react";
import axios from "axios";
import public_variables from "../../publicVar";
import { useSideMessage } from "../../components/Error/SideMessageContext";
function AdminFinancialsPage() {
    // Error Message 
    const { showMessage } = useSideMessage();
    const lang = localStorage.getItem("lang")
    const pageText = {
        "title":        lang === "english" ? "Financials": "المصاريف",
        "paid":         lang === "english" ? "Paid": "دفع",
        "addToWallet":  lang === "english" ? "Add To Wallet": "اضف الي المحفظة",
        "wallet":       lang === "english" ? "Wallet": "المحفظة",
        "didnt":        lang === "english" ? "Dind't Pay": "لم يدفع",
        "miniPaid":     lang === "english" ? "P": "د",
        "miniAddWllt":  lang === "english" ? "W": "م",
        "miniDidnt":    lang === "english" ? "D": "لم",
        "Times":        lang === "english" ? "Times": "مرة",
        "LectureName":  lang === "english" ? "Lecture Name": "اسم المحاضرة",
        "Date":         lang === "english" ? "Date": "التاريخ",
        "Status":       lang === "english" ? "Status": "الحالة",
        "selectLec":    lang === "english" ? "Select a Lecture": "أختر محاضرة",
        "selectTrack": lang === "english" ? "Select a Track": "أختر مسار",
        "submit":       lang === "english" ? "Submit": "تسليم",
        "edit":         lang === "english" ? "Edit": "تعديل",
        
    }

    // Active Track 
    const [TracksDropList,SetTrachksDropList] = useState(" - ")
    const [activeTrackID,setActiveTrackID] = useState(null)
    const [activeTrackName,setActiveTrackName] = useState(null)
    
    // Active Lecture
    const [LecturesDropList,SetLecturesDropList] = useState(<DropDownInput key ={1} placeholder={"اختر المحاضرة"} disabled={true}></DropDownInput>)
    const [activeLectureID,setActiveLectureID] = useState(null)

    // Finance Sheet
    const [financeSheet,setFinanceSheet] = useState(null)
    const [correctMessage,setCorrectMessage] = useState(null)
    const [errorMessage,setErrorMessage] = useState(null)

    // Status Counts Numbers
    var [paidCount,setPaidCount] = useState(0)
    var [unPaidCount,setUnPaidCount] = useState(0)

    // Choose Track Actions
    function ChooseTrack(event){
        setActiveTrackID(event.currentTarget.id);
        setActiveTrackName(event.currentTarget.innerHTML)
        setFinanceSheet(null);
        setActiveLectureID(null)
        setErrorMessage(null)
    }

    // Download the Sheet 
    function DownloadSheet(event){
        if (!activeTrackID || !activeTrackName){
            return
        }
        axios({
            url: public_variables.backend_url + `/files/track_financial_sheet?trackID=${activeTrackID}`,
            method: 'GET',
            responseType: 'blob', // Important
        }).then(res=>{
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `ملف المصاريف لمسار ${activeTrackName}.xlsx`); // Set the file name here
            document.body.appendChild(link);
            link.click();
        }).catch(err=>{
            console.log("Error!",err)
            showMessage(err.response.data)
        })
    }

    // Get Track ID's
    useEffect(()=>{
        axios.get(public_variables.backend_url+"/admin-tracks/assigned_tracks?adminID="+localStorage.getItem("id")).then((res)=>{

            const options = []
            for(var i=0;i<  res.data.length;i++){
                options.push(<option  onClick={ChooseTrack} id={res.data[i]['id']}>{res.data[i]['name']}</option>)
            }
            SetTrachksDropList(<DropDownInput key ={1} placeholder={"اختر المسار"}>
                                    {options}
                                </DropDownInput>)

        }).catch((err)=>{
            console.log("Error:",err)
            showMessage(err.response.data)
        })
    },[])

    // Get Lectures ID's
    useEffect(()=>{
        if(activeTrackID){
            setActiveLectureID(null)
            // SetLecturesDropList(<DropDownInput key ={1} placeholder={"اختر المحاضرة"} disabled={true}></DropDownInput>)
            axios.get(public_variables.backend_url+"/admin-lectures/lectures?trackID="+activeTrackID).then((res)=>{

                const options = []
                for(var i=0;i<  res.data.length;i++){
                    options.push(<option  onClick={(event) =>{ setActiveLectureID(event.currentTarget.id);setErrorMessage(null)}} id={res.data[i]['id']}>{res.data[i]['title']}</option>)
                }
                SetLecturesDropList(<DropDownInput key ={"track"+activeTrackID} placeholder={"اختر المحاضرة"}>
                                        {options}
                                    </DropDownInput>)
            }).catch((err)=>{
                console.log("Error:",err)
                showMessage(err.response.data)
            })
        }

    },[activeTrackID])
    
    // Get Finance Sheet
    useEffect(()=>{
        if(activeTrackID && activeLectureID){
            paidCount=0
            unPaidCount=0
            axios.get(public_variables.backend_url+`/admin-financials/financial_sheet?lectureID=${activeLectureID}&trackID=${activeTrackID}`).then((res)=>{
                console.log(res.data)
                const data = res.data
                const temp = []
                // Remove all Previous Active
                const ActiveBoxes = document.querySelectorAll(".stateBox.active")
                console.log(ActiveBoxes)
                for (var i of ActiveBoxes){
                    i.classList.remove("active")
                }

                // Addin New Students
                for (var i = 0;i<data.length;i++){
                    if (data[i]['paid']){
                        paidCount+=1
                    }else if (data[i]['paid'] === false){
                        unPaidCount+=1
                    }

                    temp.push(
                        <tr id={"student"+data[i]["studentID"]} className="StudentFinanceCard">
                            <td className="studentName">{data[i]['studentName']}</td>
                            <td className="statButtons">
                                <div className={"stateBox statusPaid"+(data[i]['paid']?" active":"")} onClick={activateStat}>
                                    <span className="bigButtons">{pageText['paid']}</span>
                                    <span className="smallButtons">{pageText['miniPaid']}</span>
                                    <span className="choiseType">paid</span>
                                </div>
                                {/* <div className="stateBox statusAmount" onClick={activateStat}>
                                    <span className="bigButtons">{pageText['wallet']}</span>
                                    <span className="smallButtons">{pageText['miniAddWllt']}</span>
                                    <span className="choiseType">wallet</span>
                                    <InputField type={"number"} name={"amount"} placeholder={"amount"} disabled={false} className={"amountOfPayment"}/>
                                </div> */}
                                <div className={"stateBox statusDidnot"+(data[i]['paid']=== false?" active":"")} onClick={activateStat}>
                                    <span className="bigButtons">{pageText['didnt']}</span>
                                    <span className="smallButtons">{pageText['miniDidnt']}</span>
                                    <span className="choiseType">didnt</span>
                                </div>
                                
                            </td>
                        </tr>
                    )
                }
                setFinanceSheet(temp)
                setPaidCount(paidCount)
                setUnPaidCount(unPaidCount)
            }).catch((err)=>{
                console.log("Error:",err)
                showMessage(err.response.data)
            })
        }
    },[activeTrackID,activeLectureID])

    function activateStat(event){

        const activeChoise = event.currentTarget.parentElement.querySelector(".active")
        if(activeChoise){
            activeChoise.classList.remove("active")
        }
        event.currentTarget.classList.add("active")
    }

    // Submit to the Database
    function SubmitSheet(event){
        if (!activeTrackID || !activeLectureID){
            setCorrectMessage(null)
            setErrorMessage("يجب اختيار مسار و محاضرة")
            return ;
        }
        const allCards = document.querySelectorAll(".StudentFinanceCard")
        const Students = []
        for(var i =0;i<allCards.length;i++){

            // Get Student's id and Status
            var id = allCards[i].id.replace("student","")
            var activeStatus = allCards[i].querySelector(".active .choiseType")

            // Convert Status To the Number
            var activeStatusBool = null;
            if (activeStatus){
                if (activeStatus.innerHTML === "paid"){
                    activeStatusBool=true
                }else if (activeStatus.innerHTML === "didnt"){
                    activeStatusBool=false
                }
            }
            // Adding to the List
            Students.push({
                studentID: id,
                paid: activeStatusBool
             })
        }

        // Constructing The Request
        const reqData = {
            trackID:activeTrackID,
            lectureID:activeLectureID,
            people:Students
        }

        axios.post(public_variables.backend_url+"/admin-financials/financial_sheet",reqData).then((res)=>{
            console.log(res.data)
            setErrorMessage(null)
            setCorrectMessage("تم تحفظ التغيرات بنجاح")
        }).catch((err)=>{
            console.log("Error!",err)
            showMessage(err.response.data)
        })
    }
    return (
        <div id="AdminFinancialsPage">
            <MainHeader title={pageText['title']} comment={""}/>
            <div className="DonwloadButtonContainer">
                <button disabled={activeTrackID?false:true} title="تحميل ملف الغياب للمسار المختار" className="DonwloadSheet" onClick={DownloadSheet}>تحميل الملف <FontAwesomeIcon icon="fa-solid fa-file-arrow-down" /></button>
            </div>
            <div className="AttendanceContainer">
                <div className="selectSection">
                    {TracksDropList}
                    {LecturesDropList}
                </div>
                <div className="NumbersBoxes">
                    <div className="NumbersBox NumberBox1">
                        <div className="icon">
                            <FontAwesomeIcon icon="fa-solid fa-clock" />
                            <p className="iconChar">{pageText['miniPaid']}</p>
                        </div>
                        <h2>{pageText['paid']}</h2>
                        <span><Counter value={paidCount} speed={5000}/> &nbsp;{pageText['Times']}</span>
                    </div>
                    
                    <div className="NumbersBox NumberBox3">
                        <div className="icon">
                            <FontAwesomeIcon icon="fa-solid fa-clock" />
                            <p className="iconChar">{pageText["miniDidnt"]}</p>
                        </div>
                        <h2>{pageText['didnt']}</h2>
                        <span><Counter value={unPaidCount} speed={5000}/> &nbsp;{pageText['Times']}</span>
                    </div>
                </div>
                <table>
                    <tr>
                    </tr>
                    {financeSheet}
                    
                </table>
                <button className="listSubmitButton" onClick={SubmitSheet}>{pageText['submit']}</button>
                <span className="correctMessage">{correctMessage}</span>
                <span className="errorMessage">{errorMessage}</span>
            </div>
        </div>
    );
}

export default AdminFinancialsPage;
