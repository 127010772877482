
import "./Settings.css"
import MainHeader from "../../components/header";
import InputField  from "../../components/Input"
import { useState } from "react";
import axios from "axios";
import public_variables from "../../publicVar";
import { validateEmail, validatePhone } from "../../functions/validate";
import { useSideMessage } from "../../components/Error/SideMessageContext";

function SettingsPage() {
    // Error Message 
    const { showMessage } = useSideMessage();
    const lang = localStorage.getItem("lang")
    const pageText = {
        "title":          lang === "english" ? "Settings": "الإعدادات",
        "Name":           lang === "english" ? "Name": "الاسم",
        "Email":       lang === "english" ? "Email": "الايميل",
        "NewEmail":    lang === "english" ? "New Email": "الايميل  الجديد",
        "Password":       lang === "english" ? "Password": "كلمة المرور",
        "NewPassword":    lang === "english" ? "New Password": "كلمة المرور الجديدة" ,
        "Langauge":       lang === "english" ? "Langauge": "اللغة",
        "edit":           lang === "english" ? "Edit": "تعديل",
        "Change":         lang === "english" ? "Change": "تغيير",
        "YourPass":       lang === "english" ? "Your Password": "كلمة مرورك",
        "reNewPassword":  lang === "english" ? "Rewrite Your Password": "اعد كتابة كلمة المرور",
        "successMessage": lang === "english" ? "Changed Successfully":"تم التغيير بنجاح"
        
    }
    // Change Form Type
    const [changeType,setChangeType] = useState("")
    
    // Form Messages
    const [errorMessage,setErrorMessage] = useState(null)
    const [successMessage,setSuccessMessage] = useState(null)

    //  Open Form For Email Mode
    function openChangeEmailForm(event){
        setChangeType("Email")

        document.getElementById("SettingsPage")
        .querySelector(".backgroundBlock")
        .style.display = "block"

        document.getElementById("SettingsPage")
        .querySelector(".changeFromContainer")
        .style.zIndex = "999"

        document.getElementById("SettingsPage")
        .querySelector(".ChangingForms").classList.add("shown")
    }

    //  Open Form For Email Mode
    function openChangePhoneForm(event){
        setChangeType("Phone")

        document.getElementById("SettingsPage")
        .querySelector(".backgroundBlock")
        .style.display = "block"

        document.getElementById("SettingsPage")
        .querySelector(".changeFromContainer")
        .style.zIndex = "999"

        document.getElementById("SettingsPage")
        .querySelector(".ChangingForms").classList.add("shown")
    }

    //  Open Form For Password Mode
    function openChangePasswordFrom(event){
        setChangeType("Password")
        document.getElementById("SettingsPage")
        .querySelector(".backgroundBlock")
        .style.display = "block"
        document.getElementById("SettingsPage")
        .querySelector(".changeFromContainer")
        .style.zIndex = "999"
        document.getElementById("SettingsPage")
        .querySelector(".ChangingForms").classList.add("shown")

        // Empty The Password Input
        document.getElementById("passwordInput").querySelector("input").value =""
    }

    // Close or Hide the Form
    function closeForm(event){
        document.getElementById("SettingsPage")
        .querySelector(".backgroundBlock")
        .style.display = "none"
        document.getElementById("SettingsPage")
        .querySelector(".ChangingForms").classList.remove("shown")
        document.getElementById("SettingsPage")
        .querySelector(".changeFromContainer")
        .style.zIndex = "-1"
    }

    // Submit Change Email Form
    function ChangeEmail(event){
        if(changeType ==="Email"){
            const password = document.getElementById("passwordInput").querySelector("input").value
            const newEmail = document.getElementById("newEmailInput").querySelector("input").value
            if(!validateEmail(newEmail)){
                setErrorMessage(" الايميل غير صحيح")
                return
            }
            const data = {
                userID: localStorage.getItem("id"),
                password:password,
                newEmail:newEmail
            }
            axios.post(public_variables.backend_url+"/settings/change_email",data).then((res)=>{
                localStorage.setItem("email",newEmail)
                setErrorMessage(null)
                setSuccessMessage(pageText['successMessage'])
                window.location.reload()
            }).catch((err)=>{
                try{
                    const errorType = err.response.data
                    setErrorMessage(errorType)
                }catch(error){
                    console.log("Error!!",error)
                    showMessage(error.response.data)
                    
                }
            })
        }
    }

    // Submit Change Phone Form
    function ChangePhone(event){
        if(changeType ==="Phone"){
            const password = document.getElementById("passwordInput").querySelector("input").value
            const newPhone = document.getElementById("newPhoneInput").querySelector("input").value
            if(!validatePhone(newPhone)){
                setErrorMessage("رقم الهاتف غير صحيح")
                return
            }
            const data = {
                userID: localStorage.getItem("id"),
                password:password,
                newPhone:newPhone
            }
            axios.post(public_variables.backend_url+"/settings/change_phone",data).then((res)=>{
                localStorage.setItem("phone",newPhone)
                setErrorMessage(null)
                setSuccessMessage(pageText['successMessage'])
                window.location.reload()
            }).catch((err)=>{
                try{
                    const errorType = err.response.data
                    setErrorMessage(errorType)
                }catch(error){
                    console.log("Error!!",error)
                    showMessage(error.response.data)
                    
                }
            })
        }
    }

    // Submit Change Password Form
    function ChangePassword(event){
        if(changeType ==="Password"){
            const oldPassword = document.getElementById("passwordInput").querySelector("input").value
            const newPassword = document.getElementById("newPasswordInput").querySelector("input").value
            const reNewPassword = document.getElementById("reNewpasswordInput").querySelector("input").value
            if (oldPassword === "" || newPassword === "" || reNewPassword === ""){
                setErrorMessage("برجاء ملء الخانات بالاسفل")
                return;
            }else if(newPassword.length <= 6){
                setErrorMessage("كلمة السر يجب ان تكون اطول من 6 احرف")
                return;   
            }else if (newPassword !== reNewPassword){
                setErrorMessage("كلمتا السر غير متطابقتين")
                return;   
            }
            console.log("password: ",oldPassword," Email: ",newPassword )
            const data = {
                userID: localStorage.getItem("id"),
                oldPassword:oldPassword,
                newPassword:newPassword
            }
            axios.post(public_variables.backend_url+"/settings/change_password",data).then((res)=>{
                setErrorMessage(null)
                setSuccessMessage(pageText['successMessage'])
                window.location.reload()
            }).catch((err)=>{
                try{
                    const errorType = err.response.data
                    setErrorMessage(errorType)
                }catch(error){
                    console.log("Error!!",error)
                    showMessage(error.response.data)
                    
                }
            })
        }
    }
    
    return (
        <div id="SettingsPage">
            <MainHeader title={pageText['title']} comment={""}/>
            <div className="settingsContainer">
                <div className="settingRow">
                    <div className="left">
                        <p>{pageText['Name']}</p>
                        <span>{localStorage.getItem("name")}</span>
                    </div>
                    <div className="right">
                        {/* <button>Edit</button> */}
                    </div>
                </div>
                <div className="settingRow">
                    <div className="left">
                        <p>{"الأيميل"}</p>
                        <span>{localStorage.getItem("email")?localStorage.getItem("email"):"لا يوجد"}</span>
                    </div>
                    <div className="right">
                        <button onClick={openChangeEmailForm}>{pageText['edit']}</button>
                    </div>
                </div>
                <div className="settingRow">
                    <div className="left">
                        <p>{"الهاتف"}</p>
                        <span>{localStorage.getItem("phone")}</span>
                    </div>
                    <div className="right">
                        <button onClick={openChangePhoneForm}>{pageText['edit']}</button>
                    </div>
                </div>
                <div className="settingRow">
                    <div className="left">
                    
                    <p>{pageText["Password"]}</p>
                        <span>**********</span>
                    </div>
                    <div className="right">
                        <button onClick={openChangePasswordFrom}>{pageText['edit']}</button>
                    </div>
                </div>
            </div>
            <div className="changeFromContainer">
                <div className="backgroundBlock" onClick={closeForm}>
                    
                </div>
                <div className="ChangingForms">
                    
                    <h2>{pageText['Change']} {pageText[changeType]}</h2>
                    <span className="errorMessage">{errorMessage}</span>
                    <span className="correctMessage">{successMessage}</span>
                    <InputField id={"passwordInput"} type={"password"}   className={"chageFormInput"}  name={"password"}  placeholder={pageText['YourPass']}/>
                    {changeType ==="Password"?
                    <>
                        <InputField id={"newPasswordInput"} type={"password"}  className={"chageFormInput"}  name={"newPassword"} placeholder={pageText['NewPassword']}/>
                        <InputField id={"reNewpasswordInput"} type={"password"}  className={"chageFormInput"}  name={"newPasswordRewrite"} placeholder={pageText['reNewPassword']}/>
                        <button onClick={ChangePassword}>{pageText['edit']}</button>
                    
                    </>:
                    changeType ==="Email"?
                    <>
                        <InputField id={"newEmailInput"} type={"text"}  className={"chageFormInput"}  name={"newEmail"} placeholder={pageText['NewEmail']}/>
                        <button onClick={ChangeEmail}>{pageText['edit']}</button>
                    
                    </>:
                    <>
                        <InputField id={"newPhoneInput"} type={"number"}  className={"chageFormInput"}  name={"newPhone"} placeholder={"الهاتف الجديد"}/>
                        <button onClick={ChangePhone}>{pageText['edit']}</button>
                    
                    </>
                    }
                    {/* <button onClick={closeForm}>{pageText['edit']}</button> */}
                </div>
            </div>

        </div>
    );
}

export default SettingsPage;
