
import "./ToDo.css"
import MainHeader from "../../components/header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import axios from "axios";
import public_variables from "../../publicVar";
import formatDateWithDaysRemaining from "../../functions/NextData";
import { useSideMessage } from "../../components/Error/SideMessageContext";

function ToDoPage() {
    // Error Message 
    const { showMessage } = useSideMessage();
    const lang = localStorage.getItem("lang")
    const pageText = {
        "title":    lang === "english" ? "My Todo": "الحضور",
        "Assinged": lang === "english" ? "Assinged": "مُكَلَّف",
        "Done":     lang === "english" ? "Done": "تم",
        "Lecture":  lang === "english" ? "Lecture": "محاضرة",
        "name":     lang === "english" ? "Task Name":"اسم التكليف",
        "track":    lang === "english" ? "Track":"المسار",
        "lecture":  lang === "english" ? "Lecture":"المحاضره",
        "date":     lang === "english" ? "Date":"التاريخ",
        "status":   lang === "english" ? "Status":"الحالة",
        
    }
    // Seting the The Acitve Wheather Done or not Done
    const [activePart,setActivePart] = useState(1)

    // Tasks Lists
    const [todoList,setTodoList] = useState([])

    // Mark Task as Done Or Not
    function markTodo(event){
        const taskID= event.currentTarget.id.replace("task","")
        if (event.currentTarget.classList.contains("done")){
            event.currentTarget.classList.remove("done")
            axios.post(public_variables.backend_url+"/todo/mark_not_done",{recordID:taskID}).then((res)=>{
                console.log("res:",res)
            }).catch((err)=>{
                console.log("Error:",err)
                showMessage(err.response.data)
            })
        }else{
            event.currentTarget.classList.add("done")
            console.log(event.currentTarget.id)
            axios.post(public_variables.backend_url+"/todo/mark_done",{recordID:taskID}).then((res)=>{
                console.log("res:",res)
            }).catch((err)=>{
                console.log("Error:",err)
                showMessage(err.response.data)
            })

        }
        

        
    }

    function setActive(event){
        event.currentTarget.parentElement.querySelector(".active").classList.remove("active")
        event.currentTarget.classList.add("active")
        if (activePart){
            setActivePart(0)
        }else{
            setActivePart(1)
        }
    }

    useEffect(()=>{
        axios.get(public_variables.backend_url+`/todo/${activePart?"tasks":"done_tasks"}?userID=${localStorage.getItem("id")}`).then((res)=>{
            console.log(res.data)
            const data = res.data
            const temp = []
            for (var i=0;i<data.length;i++){

                // Fix The Date
                var date = formatDateWithDaysRemaining(data[i]['lecture']['date']).miniformattedDate
                temp.push(
                    <tr id={"task"+data[i]['id']} className={"TaskBox " + (data[i]['done']?"done":"")}  onClick={markTodo}>
                        <td className="icons">
                            <FontAwesomeIcon className="checked" icon="fa-solid fa-square-check" />
                            <svg className="unchecked" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M384 80c8.8 0 16 7.2 16 16V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16H384zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"/></svg>
                        </td>
                        <td className="TaskTitle">{data[i]['title']}</td>
                        <td className="sub">{data[i]['track']}</td>
                        <td className="sub">{data[i]['lecture']['title']}</td>
                        <td className="sub">{date}</td>
                    </tr>
                )
            }
            setTodoList(temp)
        }).catch((err)=>{
            console.log("Error:",err)
            showMessage(err.response.data)
        })
    },[activePart])
    
    return (
        <div id="ToDoPage">
            <MainHeader title={pageText['title']} comment={""}/>
            <div className="TracksContainer">
                <div className={"titleContainer"}>
                    <h2 className="title active" onClick={setActive}>{pageText['Assinged']}</h2>
                    <h2 className="title" onClick={setActive}>{pageText['Done']}</h2>

                </div>
                <div className="tasks">
                    <table>
                        <thead className="tableHead">
                            <th>{pageText['status']}</th>
                            <th>{pageText['name']}</th>
                            <th>{pageText["track"]}</th>
                            <th>{pageText['lecture']}</th>
                            <th>{pageText['date']}</th>
                        </thead>
                        {todoList}
                    </table>
                </div>
                
            </div>
        </div>
    );
}

export default ToDoPage;
