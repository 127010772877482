import { useParams } from "react-router";
import { useState } from "react";
import {jwtDecode} from 'jwt-decode';

import InputField from "../../components/Input";
import background from "../../content/Login.png"
import logoImage from "../../content/logo.png"
import public_variables from "../../publicVar";
import axios from "axios";
import "./Reset.css"
import { useSideMessage } from "../../components/Error/SideMessageContext";
function ResetPage() {
// Error Message 
const { showMessage } = useSideMessage();
  // Massage
  const [message,setMessage] = useState(null)
  const [isError,setIsError] = useState(false)


  // Submit to API
  function submitTheLoginForm(even){
    // window.location.href = "/login"
    const code = document.getElementById("codeInput").querySelector("input").value
    const password = document.getElementById("passwordInput").querySelector("input").value
    const repassword = document.getElementById("rePasswordInput").querySelector("input").value
    
    // Form Validation
    if (code === "" || password === "" || repassword === ""){
      setIsError(true)
      setMessage("برجاء  ملئ الخانات بالاسفل")
      return
    }else if(password.length <= 6){
      setIsError(true)
      setMessage("كلمة السر يجب ان تكون اطول من 6 احرف")
      return
    }else if(password !== repassword){
      setIsError(true)
      setMessage("كلمتا السر غير متطابقتين")
      return
    }

    //  Clear the Message
    setIsError(false)
    setMessage(null)

    // Construct the Request Data
    const reqData = {
      code:code,
      email: localStorage.getItem("tempEmail"),
      newPassword:password
      
    }

    // Send the Request
    axios.post(public_variables.backend_url+"/auth/reset_password",reqData).then(res=>{
      setIsError(false)
      setMessage("تم تغيير كلمة السر")
      localStorage.clear()
      window.location.href = "/login"
    }).catch(err=>{
      setIsError(true)
      setMessage(err.response.data)
      console.log("Error!!")
      showMessage(err.response.data)
    })

  }

  // Handle From With Enter Key
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default form submission behavior
      submitTheLoginForm();
    }
  };
  return (
    <div id="ResetPage">
      <img src={background} className="background" />
      <div className="loginSection">
        <div className="logoImageContainer">
          <img src={logoImage} className="logoImage"/>
        </div>
        <div className="loginFormContainer">
          <div className="loginform">
            <h1 className="formTitle">اهلا بك في مشكاة</h1>
            <h3> تغيير  كلمة المرور</h3>
            <span className={isError?"errorMessage":"correctMessage"}>{message}</span>
            <p className="ErrorMessage"></p>
            <InputField type={"text"} id={"codeInput"} name={"code"} placeholder={"ادخل الرمز التاكيدي المرسل"} className={"usernameFiled"} onKeyDown={handleKeyDown}/>
            <InputField type={"password"} id={"passwordInput"} name={"password"} placeholder={"كلمة المرور الجديدة"} className={"usernameFiled"} onKeyDown={handleKeyDown}/>
            <InputField type={"password"} id={"rePasswordInput"} name={"repassword"} placeholder={"اعد كتابة كلمة المرور"} className={"usernameFiled"} onKeyDown={handleKeyDown}/>
            <a href="#" className="forgetPassword"> اعاده ارسال الرمز</a>
            <button onClick={submitTheLoginForm}>تغيير كلمة المرور</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPage;
