// NotFoundPage.js
import React from 'react';
import './RouteNotFound.css'
import image404 from '../../content/404NotFound.jpg'
function NotFoundPage() {
  return (
    <div id='RouteNotFound'>
        <img src={image404}/>
      <h1>404 - الصفحة غير موجودة</h1>
    </div>
  );
}

export default NotFoundPage;