import './App.css';
import './components/styles/general.css'
import './components/FontawesomeIcons'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import LoginForm            from './pages/login/Login';
import SideNavBar           from './components/SideNavBar';
import SideMessage           from './components/Error/SideMessage';
import Login from './pages/Login/Login';
import OverviewPage from './pages/overview/Overview';
import AttendancePage from './pages/Attendance/Attendance';
import SettingsPage from './pages/Settings/Settings';
import ForgetPage from './pages/Forget/Forget';
import ResetPage from './pages/ResetPassword/Reset';
import LecturesPage from './pages/Lectures/Lectures';
import TracksPage from './pages/Tracks/Tracks';
import ToDoPage from './pages/ToDo/ToDo';
import AdminAttendancePage from './AdminPages/Attendance/AdminAttendance';
import AdminFinancialsPage from './AdminPages/FinancePage/Financials';
import AdminLecturesPage from './AdminPages/AdminLectures/AdminLectures';
import AdminSideNavBar from './components/AdminSideNavBar';
import SuperAdminSideNavBar from './components/SuperAdminSideNavBar';
import AdminTracksPage from './AdminPages/AdminTracks/AdminTracks';
import AdminsPage from './AdminPages/Admins/Admins';
import SuperAdminTracksPage from './AdminPages/SuperAdminTracks/SuperAdminTracks';
import RegisterPage from './pages/Register/Register';
import AdminRegisterPage from './AdminPages/AdminRegister/AdminRegister';
import TrackInfoPage from './pages/TrackInfo/TrackInfo';
import EmailVerficationPage from './pages/EmailVerfication/EmailVerficationPage';
import StudentsPage from './AdminPages/Students/StudentsPage';
import FormResponsesPage from './AdminPages/FormResponses/FormResponses';
import SignupPage from './pages/Signup/Signup';
import DownPage from './SidePages/DownPage/DownPage';
import AdminTrackAssignPage from './AdminPages/AdminTrackAssign/AdminTrackAssign';
import NotFoundPage from './SidePages/RouteNotFoundPage/RouteNotFound';

function App() {
  return (
    <div className={"App ar"}>
        <DownPage/>
        <SideMessage />

        <Router>
          <Routes>
          {/* <Route path='/downserver' element={} /> */}
          <Route path='/login' element={<Login/>} />
          <Route path='/forget' element={<ForgetPage/>} />
          <Route path='/reset' element={<ResetPage/>} />
          <Route path='/verf' element={<EmailVerficationPage/>} />
          <Route path='/trackinfo/:token' element={<TrackInfoPage/>} />
          <Route path='/register/:token' element={<RegisterPage/>} />
          <Route path='/signup/:token' element={<SignupPage/>} />
          <Route path='/adregister/:token' element={<AdminRegisterPage/>} />
          <Route path='/adassign/:token' element={<AdminTrackAssignPage/>} />
          
            {localStorage.getItem("role") === "admin"?
            <Route path='/*' element={
              <>
                <AdminSideNavBar />
                <Routes>
                  <Route path='/' element={<Navigate to="/tracks"/>} />
                  <Route path='/attendance' element={<AdminAttendancePage />} />
                  <Route path='/financials' element={<AdminFinancialsPage />} />
                  <Route path='/lectures' element={<AdminLecturesPage />} />
                  <Route path='/tracks' element={<AdminTracksPage />} />
                  <Route path='/settings' element={<SettingsPage />} />
                  <Route path='*' element={<NotFoundPage />} />

                </Routes>
              </>
            }/>
            :
            (
              localStorage.getItem("role") === "super-admin"?
                <Route path='/*' element={
                  <>
                    <SuperAdminSideNavBar />
                    <Routes>
                      <Route path='/' element={<Navigate to="/tracks"/>} />
                      <Route path='/attendance' element={<AdminAttendancePage />} />
                      <Route path='/financials' element={<AdminFinancialsPage />} />
                      <Route path='/lectures' element={<AdminLecturesPage />} />
                      <Route path='/tracks' element={<SuperAdminTracksPage />} />
                      <Route path='/formresponses/:trackID' element={<FormResponsesPage />} />
                      <Route path='/students' element={<StudentsPage />} />
                      <Route path='/admins' element={<AdminsPage />} />
                      <Route path='/settings' element={<SettingsPage />} />
                      <Route path='*' element={<NotFoundPage />} />
                      

                    </Routes>
                  </>
                } />
              :
                <Route path='/*' element={
                    <>
                    <SideNavBar />
                      <Routes>
                          <Route path='/' element={<Navigate to="/overview"/>} />
                          <Route path='/overview' element={<OverviewPage/>} />
                          <Route path='/attendance' element={<AttendancePage />} />
                          <Route path='/lectures' element={<LecturesPage />} />
                          <Route path='/tracks' element={<TracksPage />} />
                          <Route path='/todo' element={<ToDoPage />} />
                          <Route path='/settings' element={<SettingsPage />} />
                          <Route path='*' element={<NotFoundPage />} />
                          
                      </Routes>
                  </>
              } />
            )
              
            }

            <Route path='*' element={<NotFoundPage />} />
          </Routes>
        </Router>
      
    </div>
  );
}

export default App;
