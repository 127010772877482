import './styles/InputField.css'
function InputField({type,name,placeholder,value,disabled,required=false,className,id,onKeyDown}) {
  return (
    <div id={id} className={"InputFieldBox "+className}>
        <input type={type} placeholder="" id={name} defaultValue={value} onKeyDown={onKeyDown} maxLength={500}/>
        <label for={name}>{required?<span className='reqiued_star'>*</span>:''}{placeholder}</label>
    </div>
  );
}

export default InputField;
