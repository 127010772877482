
import "./Admins.css"
import adminImage from '../../content/adminImage.png'
import MainHeader from "../../components/header";
import { useEffect, useState } from "react";
import axios from "../../functions/axiosAuth";
import public_variables from "../../publicVar";
import DropDownInput from "../../components/DropDownInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputField from "../../components/Input";
import { useSideMessage } from "../../components/Error/SideMessageContext";

function AdminsPage() {
    // Error Message 
    const { showMessage } = useSideMessage();
    // Messages
    const [message,setMessage] = useState(null)
    const [errorMessage,setMessageError] = useState(false)

    // Form Messages
    const [formMessage,setFormMessage] = useState(null)
    const [errorFormMessage,setMessageErrorForm] = useState(false)

    // Tracks and Admins Lists
    const [TracksOptions,SetTracksDropList] = useState([])
    const [choosedTracks,setChoosedTracks] = useState([])
    const [adminsList,setAdminsList] = useState(null)

    // Add New Admin Token
    const [adminToken,setAdminToken] = useState(null)

    // Counter to Rested he Form 
    const [counter,setCounter] = useState(0)

    // Add New Track to Admin
    function AssingTrack(event){
        const adminID = event.currentTarget.closest(".adminCard").id.replace("admin","")
        const trackID = event.currentTarget.id
        const reqData = {
            adminID:adminID,
            trackID:trackID 
        } 
        console.log("adminID",adminID," : ",trackID)
        axios.post(public_variables.backend_url+"/super-admin/assign_track",reqData).then((res)=>{
            window.location.reload()
        }).catch(err=>{
            console.log("Error!!",err)
            showMessage(err.response.data)

            setMessage(err.response.data)
            setMessageError(true)
        })
    }

    // Remove Track Assingment
    function unAssingTrack(event){
        const ID = event.currentTarget.parentElement.id.replace("track","")
        const adminID = event.currentTarget.closest(".adminCard").id.replace("admin","")
        const TracksNumber = event.currentTarget.closest(".adminCard").querySelectorAll(".trackCard")
        if(TracksNumber.length <=1){
            setMessage("لا يمكن حذف اخر مسار, يمكن ان تحذف الحساب")
            setMessageError(true)
            return 
        }
        setMessage(null)
        const reqData = {
            trackID:ID,
            adminID:adminID
        }
        // Send to Server
        axios.delete(public_variables.backend_url+"/super-admin/assign_track",{data:reqData}).then((res)=>{
            console.log(res.data)
            window.location.reload()
        }).catch(err=>{
            console.log("Error!!",err)
            showMessage(err.response.data)
        })
        console.log("AdminID: ",adminID," TrackID: ",ID)
    }

    // Select Delete  to Show the Delete Button
    function ShowDeleteButton(event){
        event.currentTarget.parentElement.querySelector(".sureButton").classList.remove("hide")
    }

    // Block an Account
    function BlockAccount(event){
        const mainCard = event.currentTarget.closest(".adminCard")
        const adminID = mainCard.id.replace("admin","")
        const reqData = {
            adminID:adminID
        }
        axios.post(public_variables.backend_url+"/super-admin/admin_block",reqData).then((res)=>{
            mainCard.querySelector(".unblockButton").classList.remove("hide")
            mainCard.querySelector(".blockButton").classList.add("hide")
        }).catch(err=>{
            console.log("Error!!",err)
            showMessage(err.response.data)
        })
    }

    // un-Block an Account
    function unBlockAccount(event){
        const mainCard = event.currentTarget.closest(".adminCard")
        const adminID = mainCard.id.replace("admin","")
        const reqData = {
            adminID:adminID
        }
        axios.delete(public_variables.backend_url+"/super-admin/admin_block",{data:reqData}).then((res)=>{
            mainCard.querySelector(".unblockButton").classList.add("hide")
            mainCard.querySelector(".blockButton").classList.remove("hide")
        }).catch(err=>{
            console.log("Error!!",err)
            showMessage(err.response.data)
        })
    }

    // Delete an Account
    function DeleteAccount(event){
        const adminID = event.currentTarget.closest(".adminCard").id.replace("admin","")
        const reqData = {
            adminID:adminID
        }
        axios.delete(public_variables.backend_url+"/super-admin/admin",{data:reqData}).then((res)=>{
            window.location.reload()
        }).catch(err=>{
            console.log("Error!!",err)
            showMessage(err.response.data)
        })
    }

    // Open Add New Admin Form
    function openForm(event){
        document.getElementById("AdminsPage")
        .querySelector(".backgroundBlock")
        .style.display = "block"
        document.getElementById("AdminsPage")
        .querySelector(".AddNewFormContainer")
        .style.zIndex = "999"
        document.getElementById("AdminsPage")
        .querySelector(".AddNewForm").classList.add("shown")

        setMessage(null)
    }

    // Clode Add-New Admin Form
    function closeForm(event){
        document.getElementById("AdminsPage")
        .querySelector(".backgroundBlock")
        .style.display = "none"
        document.getElementById("AdminsPage")
        .querySelector(".AddNewForm").classList.remove("shown")
        document.getElementById("AdminsPage")
        .querySelector(".AddNewFormContainer")
        .style.zIndex = "-1"
        document.getElementById("AdminsPage")
        .querySelector(".GeneratedLink").classList.remove("shown")

        setFormMessage(null)
        setChoosedTracks([])
        setCounter(counter+1)
    }

    //Add Track to Choosed Track List
    function AddToChoosedTracks(event){
        const trackID = event.currentTarget.id
        const trackName = event.currentTarget.innerHTML
        for (var t of choosedTracks){
            if (t['id'] == trackID){
                setFormMessage("تمت اضافة المسار بالفعل")
                setMessageErrorForm(true)
                return 
            }
        }
        setFormMessage(null)
        setChoosedTracks([...choosedTracks,{id:trackID,name:trackName}])
    }

    // Add New Admi
    function AddNewAdmin(event){
        if (choosedTracks.length ===0){
            setFormMessage("يجب اختيار مسار للمسؤول")
            setMessageErrorForm(true)
            return
        }
        const dayLimit = document.getElementById("DayLimitInput").querySelector("input").value
        if (dayLimit === ""){
            setFormMessage("يجب اختيار مدة صلاحية الرابط")
            setMessageErrorForm(true)
            return
        }
        const choosedTracksID = []
        for (var track of choosedTracks){
            choosedTracksID.push(track['id'])
        }
        const reqData = {
            tracks:choosedTracks,
            daysLimit:dayLimit
        }
        axios.post(public_variables.backend_url+"/super-admin/admins_link",reqData).then((res)=>{
            document.getElementById("AdminsPage")
            .querySelector(".AddNewForm").classList.remove("shown")
    
            document.getElementById("AdminsPage")
            .querySelector(".GeneratedLink").classList.add("shown")
            console.log(res.data)
            setAdminToken(res.data)
        }).catch((err)=>{
            setFormMessage("حدث خطأ في انشاء الرابط, برجاء التواصل مع المسئول")
            setMessageErrorForm(true)
            console.log("Error!!",err)
            showMessage(err.response.data)
        })

    }

    // Copy the Link to Clipboard
    function copyToClipboard (event){
        const text = event.currentTarget.parentElement.querySelector(".link").value
        const copiedSpan = event.currentTarget.parentElement.querySelector(".copiedText")
        const copiedSVG = event.currentTarget.parentElement.querySelector(".CopyButton")
        console.log(copiedSVG)
        navigator.clipboard.writeText(text).then(() => {
            console.log(text)
            copiedSpan.classList.remove("hide")
            copiedSVG.classList.add("hide")
            setTimeout(() => {
            copiedSpan.classList.add("hide")
            copiedSVG.classList.remove("hide")
        }, 2000);
        });
    };

    // Get  Tracks
    useEffect(()=>{
            axios.get(public_variables.backend_url+`/super-admin/tracks`)
            .then((res)=>{ 
                if (res.data){
                    SetTracksDropList(res.data)
                }               

            }).catch((err)=>{
                console.log("Error!",err)
                showMessage(err.response.data)
            })
    },[])

    // Get Admins List
    useEffect(()=>{
        if (TracksOptions){
            axios.get(public_variables.backend_url+"/super-admin/admins_list").then((res)=>{
                console.log(res.data)
                const data = res.data
                const temp = []
    
                // Adding Admins With Thier Tracks
                for (var i=0;i<data.length;i++){
                    temp.push(
                        <div className="adminCard" id={"admin"+data[i]['id']}>
                            <div className="left">
                                <img src={adminImage} className="adminProfileImage"/>
                                <div className="cardInfo">
                                    <p className="user">{data[i]['name']}</p>
                                    <div className="assingedTracks">
                                        {data[i]['tracks'].map(track=>{
                                            return <span className="trackCard" id={"track"+track.id}>{track.name} <button className="removeTrackButton" onClick={unAssingTrack} title={"ازالة المسار من المشرف"}>X</button></span>
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="right">
                                <DropDownInput key ={1} placeholder={"أضف مسار"}>
                                    {TracksOptions.map(track=>{
                                        return <option  onClick={AssingTrack} id={track['id']}>{track['name']}</option>
                                    })}
                                </DropDownInput>
                               
                                     
                                <p className={"deleteButton blockButton" + (data[i]['active'] ? "": " hide")} onClick={BlockAccount}>وقف الحساب</p>
                                <p className={"undeleteButton unblockButton"+ (data[i]['active'] ? " hide":'')} onClick={unBlockAccount}>ازاله وقف الحساب</p>
                                <p className="deleteButton" onClick={ShowDeleteButton}>حذف الحساب</p>
                                <p className="sureButton hide" onClick={DeleteAccount}>هل انت متأكد</p>
                            </div>
                        </div>
                    )
                }

                if(!temp.length){
                    setAdminsList(<span className="emptyMessage">لا يوجد مشرفين</span>)
                }else{
                    setAdminsList(temp)
                }
            }).catch(err=>{
                console.log("Error!!",err)
                showMessage(err.response.data)
            })  
        }

    },[TracksOptions])

    return (
        <div id="AdminsPage">
            <MainHeader title={"المشرفين"} comment={""}/>
            <div className="AdminsContainer">
                {/* Add Admin Button */}
                <div className="AddNewButtonsContainer">
                    <button className="AddNewButtons" onClick={openForm}><FontAwesomeIcon icon="fa-solid fa-plus" />  اضف مشرف</button>
                </div>

                {/*  Error or Correct Message Span  */}
                <span className={errorMessage?"errorMessage":"correctMessage"}>{message}</span>

                {/* Admins List */}
                {adminsList}

                {/* Add New Admin Form */}
                <div className="AddNewFormContainer">
                    <div className="backgroundBlock" onClick={closeForm}></div>
                    <div className="AddNewForm">
                        <h2>اضافة مشرف جديد</h2>
                        <span className={errorFormMessage?"errorMessage":"correctMessage"}>{formMessage}</span>
                        <h3 className="choosenHeader">المسارات المختارة:</h3>
                        <div className="ChoosenTrackContainer">
                            {choosedTracks.length?
                            choosedTracks.map(track=><span className="ChoosedTrackOption">{track["name"]}</span>)
                            :
                            <span>لم يتم اختيار  مسارات</span>
                            }
                        </div>
                        {TracksOptions?
                        <DropDownInput key ={`tracks${counter}`} placeholder={"اختر مسار للمشرف"}>
                            {TracksOptions.map(track=>{
                                return <option  onClick={AddToChoosedTracks} id={track['id']}>{track['name']}</option>
                            })}
                        </DropDownInput>
                        :''}
                        <InputField key={`daysLimit${counter}`} type={"number"} id={"DayLimitInput"}  className={"chageFormInput"} name={"DayLimit"} placeholder={"صلاحية الرابط بالايام"}/>

                        <button onClick={AddNewAdmin}>انشئ رابط</button>
                    </div>
                    <div className="GeneratedLink">
                        <h2>الرابط</h2>
                        <div className="LinkRow">
                            <input type="text" className="link" value={public_variables.fronend_url+public_variables.admin_registraion+adminToken}/>
                            <button onClick={copyToClipboard} className="CopyButton"> نسخ <FontAwesomeIcon icon="fa-solid fa-copy"/></button>
                            <button className="copiedText hide">Copied</button>
                        </div>

                    </div>
                </div>

            </div>
        </div>  
    );
}

export default AdminsPage;
