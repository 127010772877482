import { useParams } from "react-router";
import { useState } from "react";
import {jwtDecode} from 'jwt-decode';

import InputField from "../../components/Input";
import background from "../../content/Login.png"
import logoImage from "../../content/logo.png"
import public_variables from "../../publicVar";
import axios from "axios";
import "./EmailVerficationPage.css"
import { validateEmail } from "../../functions/validate";
import checkLoggedIn from "../../functions/checkLogin";
import { useSideMessage } from "../../components/Error/SideMessageContext";
function EmailVerficationPage() {
  // Check if Already Logged-in, Redirect to home ./
  checkLoggedIn()

  // Error Message 
  const { showMessage } = useSideMessage();

  // Massage
  const [message,setMessage] = useState(null)
  const [isError,setIsError] = useState(false)


  // Code Status
  const [codeSent,setCodeSent] = useState(false)
  const [email,setEmail] = useState(false)


  // Submit to API
  function submitTheLoginForm(even){
    // window.location.href = "/login"
    const code = document.getElementById("codeInput").querySelector("input").value

    // Form Validation
    if (code === "" ){
      setIsError(true)
      setMessage("برجاء  ملئ الخانات بالاسفل")
      return
    }

    //  Clear the Message
    setIsError(false)
    setMessage(null)

    // Construct the Request Data
    const reqData = {
      code:code,
      email: email,
      
    }

    // Send the Request
    axios.post(public_variables.backend_url+"/auth/verify_account",reqData).then(res=>{
      setIsError(false)
      setMessage("تم تفعيل الحساب")
      localStorage.clear()
      window.location.href = "/login"
    }).catch(err=>{
      setIsError(true)
      setMessage(err.response.data)
      console.log("Error!!")
      showMessage(err.response.data)

    })

  }

  // Send Verf Code to Email
  function sendEmail(event){
    const email = document.getElementById("emailInput").querySelector("input").value

    if(email === ""){
      setIsError(true)
      setMessage("برجاء كتابة الايميل")
      return
    }else if(!validateEmail(email)){
      setIsError(true)
      setMessage("الأيميل غير صحيح")
      return 
    }

    // Construct the Request Data
    const reqData = {
      "email":email,
      "reason":"verify"
    }
    axios.post(public_variables.backend_url+"/auth/send_email",reqData).then(res=>{
      setIsError(false)
      setMessage("تم ارسال الكود")
      setCodeSent(true)
      setEmail(email)
    }).catch((err)=>{
      setIsError(true)
      console.log("Error!!")
      console.log(err)
      setMessage(err.response.data)
      showMessage(err.response.data)

    })
  }
  
  // Handle From With Enter Key
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default form submission behavior
      submitTheLoginForm();
    }
  };
  return (
    <div id="ResetPage">
      <img src={background} className="background" />
      <div className="loginSection">
        <div className="logoImageContainer">
          <img src={logoImage} className="logoImage"/>
        </div>
        <div className="loginFormContainer">

            

            {codeSent?
            <div className="loginform">
              <h1 className="formTitle">اهلا بك في مشكاة</h1>
              <h3> تفعيل الحساب</h3>
              <span className={isError?"errorMessage":"correctMessage"}>{message}</span>
              <p className="ErrorMessage"></p>
              <InputField type={"email"} id={"emailInput2"} name={"email"} placeholder={"البريد الألكتروني"} className={"usernameFiled"} value={email} disabled={true} onKeyDown={handleKeyDown}/>

              <InputField type={"text"} id={"codeInput"} name={"code"} placeholder={"الرمز التاكيدي المرسل"} className={"usernameFiled"} onKeyDown={handleKeyDown}/>
              <a href="#" className="forgetPassword" onClick={sendEmail}> اعاده ارسال الرمز</a>
              <button onClick={submitTheLoginForm}>تفعيل الحساب</button>
            </div>
            :
            <div className="loginform">
              <h1 className="formTitle">اهلا بك في مشكاة</h1>
              <h3> تفعيل الحساب</h3>
              <span className={isError?"errorMessage":"correctMessage"}>{message}</span>
              <p className="ErrorMessage"></p>
              <InputField type={"email"} id={"emailInput"} name={"email"} placeholder={"البريد الألكتروني"} className={"usernameFiled"} onKeyDown={handleKeyDown}/>
              <button onClick={sendEmail}>ارسل الرمز التاكيدي</button>
            </div>
            }
          </div>
      </div>
    </div>
  );
}

export default EmailVerficationPage;
