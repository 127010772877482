import React from 'react';
import SideNavigation from './SideNavFunction';
import SideNavigationButton from './SideNavButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function SideNavBar() {

  const navList = ['overview',"attendance","tracks","lectures","todo","settings"] 
  const defualtRoute = "overview"
    const lang =  localStorage.getItem('lang') 
    const compText = {
        overview:      lang === 'english' ? "Overview":"الرئيسية",
        attendance:    lang === 'english' ? "Attendance":"الحضور",
        tracks:       lang === 'english' ? "Tracks":"المسارات",
        lectures:      lang === 'english' ? "Lectures":"المحاضرات",
        todo:          lang === 'english' ? "Todo":"المهام",
        settings:      lang === 'english' ? "Settings":"الاعدادات"
    }
    function showHideMinu(){
        if(lang === "english"){
            document.getElementsByClassName('sideNav')[0].style.setProperty('left','0px')
            document.querySelector('.sideNav').querySelector('.backgroundBlock').style.left = document.getElementsByClassName('sideNav')[0].offsetWidth + "px"

        }else{
            document.getElementsByClassName('sideNav')[0].style.setProperty('right','0px')
            document.querySelector('.sideNav').querySelector('.backgroundBlock').style.left = "-"+document.getElementsByClassName('sideNav')[0].offsetWidth + "px"

        }
        
        document.querySelector('.sideNav').querySelector('.backgroundBlock').style.setProperty('display','block')
    }
return (
    <div>
        <div className="sideMinu row">
            <FontAwesomeIcon icon="fa-solid fa-list" onClick={showHideMinu} />   
        </div>
        <SideNavigation defualtRoute={defualtRoute} navList={navList}>
            <SideNavigationButton route={"overview"} text={compText["overview"]} icon={<FontAwesomeIcon icon="fa-solid fa-chart-line" />}/>
            <SideNavigationButton route={"tracks"} text={compText["tracks"]} icon={<FontAwesomeIcon icon="fa-solid fa-book" />}/>
            <SideNavigationButton route={"lectures"} text={compText["lectures"]} icon={<FontAwesomeIcon icon="fa-solid fa-chalkboard-user" />}/>
            <SideNavigationButton route={"attendance"} text={compText["attendance"]} icon={<FontAwesomeIcon icon="fa-solid fa-user-check" />}/>
            <SideNavigationButton route={"todo"} text={compText["todo"]} icon={<FontAwesomeIcon icon="fa-solid fa-clipboard-check" />}/>
            <SideNavigationButton route={"settings"} text={compText["settings"]} icon={<FontAwesomeIcon icon="fa-solid fa-gear" />}/>
        </SideNavigation>
    </div>
)
}

export default SideNavBar;
