import axios from "../../functions/axiosAuth"
import InputField from "../../components/Input";
import background from "../../content/Login.png"
import logoImage from "../../content/logo.png"
import public_variables from "../../publicVar";

import "./login.css"
import { useEffect, useState } from "react";
import {validateEmail, validatePhone} from "../../functions/validate";
import checkLoggedIn from "../../functions/checkLogin";
import { useSideMessage } from "../../components/Error/SideMessageContext";

function Login() {

  useEffect(()=>{
    // Check if Already Logged-in, Redirect to home ./
    if(!localStorage.getItem("redirected")){
      console.log("hello")
      checkLoggedIn('/')
    }
  },[])

  // Error Message 
  const { showMessage } = useSideMessage();

  // Massage
  const [message,setMessage] = useState(null)
  const [isError,setIsError] = useState(false)
  
  // Send Data To API
  function submitTheLoginForm(event){
    const email = document.getElementById("emailInput").querySelector("input").value
    const password = document.getElementById("passwordInput").querySelector("input").value
    if(email === "" || password === ""){
      setIsError(true)
      setMessage("برجاء ملئ الخانات بالاسفل")
      return
    }else if (!validateEmail(email) && !validatePhone(email)){
      setIsError(true)
      setMessage("الأيميل او رقم الهاتف غير صحيح")
      return
    }
    // Clear the Message
    setIsError(false)
    setMessage(null)
    const reqData = {
      phone_or_email:email,
      password:password
    }
    axios.post(public_variables.backend_url+"/auth/login",reqData).then(res=>{
      setIsError(false)
      setMessage("يتم تسجيل الدخول")

      const data = res.data
      for(var i of Object.keys(data)){
        localStorage.setItem(i,data[i])
      }
      const redirected = localStorage.getItem("redirected")
      if (redirected){
 
        localStorage.removeItem("redirected")
        if (redirected.includes("/")){
          console.log("hello")
          window.location.href = redirected
        }else{
          window.history.back()
        }
      console.log("hello")
      }else{
        window.location.href = "/"
      }

    }).catch((error)=>{
      setIsError(true)
      setMessage(error.response.data)

      // Check to Redirect to Verfication Page if Needed
      if (error.response.data === "الحساب غير مفعل"){
        setMessage(<span> الحساب غير مفعل, برجاء  <a href='/verf'>تفعيل الحساب</a></span>)
      }
      
      console.log("Error!!",error)
      showMessage(error.response.data)
    })
  }


  // Handle From With Enter Key
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default form submission behavior
      submitTheLoginForm();
    }
  };

  return (
    <div id="LoginPage">
      <img src={background} className="background" />
      <div className="loginSection">
        <div className="logoImageContainer">
          <img src={logoImage} className="logoImage"/>
        </div>
        <div className="loginFormContainer">
          <div className="loginform">
            <h1 className="formTitle">اهلا بك في مشكاة</h1>
            <h3>قم بتسجيل الدخول</h3>
            <span className={isError?"errorMessage":"correctMessage"}>{message}</span>
            <InputField type={"email"} id={"emailInput"} name={"email"} placeholder={"البريد الألكتروني او رقم الهاتف"} className={"usernameFiled"} onKeyDown={handleKeyDown}/>
            <InputField type={"password"} id={"passwordInput"} name={"password"} placeholder={"كلمة السر"}  className={"passwordFiled"} onKeyDown={handleKeyDown}/>
            <a href="/forget" className="forgetPassword">نسيت كلمة السر؟</a>
            <button onClick={submitTheLoginForm}>تسجيل الدخول</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
