import "./styles/TrackBox.css"
import defualtImage from '../content/defualt.png'
function TrackBox({id,key,title,className,description,imgLink,lectureCount}) {

  return (
    <div className={"TrackBox "+(className?className:"")} id={id} key={key}>
        <div className="right trackInfo">
            <h2 className="trackTitle">مسار {title}</h2>
            <p className="trackDescription">{description}</p>
        </div>
        <div className="left">
            {/* <img className={"trackCover"} src={imgLink}/> */}
            <img className={"trackCover"} src={defualtImage}/>
            <div>
                <span>مدة الدراسة: {lectureCount} محاضرة</span>
            </div>
        </div>
    </div>
  );
}

export default TrackBox;
