import React, { useState, useEffect } from 'react';
import './styles/GraphBar.css'; // Assuming your CSS file is named PaymentBar.css

const GraphBar = ({ value, textValue, title, color, sideValue }) => {
    const [filledWidth, setFilledWidth] = useState(0);

    useEffect(() => {
        // Simulate animation by delaying state update
        const animationDelay = 100; // Adjust animation delay as needed
        setTimeout(() => {
            setFilledWidth(value);
        }, animationDelay);
    }, [value]);

    const percentage = Math.min(Math.max(value, 0), 100); // Ensure percentage is between 0 and 100
    const filledBarStyle = {
        width: `${filledWidth}%`,
        backgroundColor: color || '#197BBD' // Default color if not provided
    };

    return (
        <div className="GraphBar">
            
            <div className='BarContainer'>
                <div className={"Totalbar " + (sideValue?"partwidth":" fullWidth")}>
                    <div className="filledBar" style={filledBarStyle}></div>
                </div>
                {sideValue? <span className='sideValue'>{textValue}</span>:''}
            </div>
            <div className="row">
                <p>{title}</p>
                {sideValue? "":<span className='sideValue'>{textValue}</span>}
            </div>
        </div>
    );
};

export default GraphBar;
