
import OverviewBox from "../../components/OverviewBox";
import "./overview.css"
import GraphBar from "../../components/GraphBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MainHeader from "../../components/header";
import DropDownInput from "../../components/DropDownInput";
import axios from "../../functions/axiosAuth"
import public_variables from "../../publicVar";
import { useEffect, useState } from "react";
import formatDateWithDaysRemaining from "../../functions/NextData";
import formattedDate from '../../functions/DateFix'
import { useSideMessage } from "../../components/Error/SideMessageContext";
function OverviewPage() {
    // Error Message 
    const { showMessage } = useSideMessage();

    const lang = localStorage.getItem("lang")

    const pageText = {
        "overview":      lang === "english" ? "Overview":"الرئيسية",
        "pageComment":   lang === "english" ? "Take a Look at Your information Overview":"الق نظرة عامة علي معلوماتك في مسار: ",
        "MyFinance":     lang === "english" ? "My Financials Status":"الدفع الخاص بك",
        "ExpireDate":    lang === "english" ? "Expire Date":"تاريخ الانتهاء",
        "CardHolder":    lang === "english" ? "Card Holder":"حامل البطاقه",
        "paid" :         lang === "english" ? "Paid Lectures":"محاضرات تم دفعها",
        "Total":         lang === "english" ? "Total Lectures":"العدد الكلي للمحاضرات",
        "yourPay":       lang === "english" ? "Your Have to Pay":"محاضرات عليك دفعها",
        "statistics":    lang === "english" ? "Outcome Statistics":"إحصائيات النتائج",
        "weeklyPayment": lang === "english" ? "Paid Lectures":"المحاضرات المدفوعة ",
        "assignments":   lang === "english" ? "Assingments":"المهام",
        "attendance":    lang === "english" ? "Attendance":"الحضور",
        "dontForget":    lang === "english" ? "Don't Forget Allah":"لا تنس ذكر الله",
        "lectures":      lang === "english" ? "Lectures":"المحاضرات",
        "remainingLecs": lang === "english" ? "Next Lecture":"المحاضرة القادمة ",
        "name":          lang === "english" ? "Name":"الاسم",
        "Track":        lang === "english" ? "Track":"مسار",
        "Date":          lang === "english" ? "Date":"التاريخ",
        "status":        lang === "english" ? "Status":"الحالة"
        
    }


    // Active Track 
    const [TracksDropList,SetTrachksDropList] = useState(" - ")
    const [activeTrackID,setActiveTrackID] = useState(null)

    // Finanicals
    // const [Paid,SetPaid] = useState('-')
    // const [Late,SetLate] = useState('-')
    // const [Total,SetTotal] = useState('-')
    // const [PaidPercentage,SetPaidPercentage] = useState(0)

    // Statistics
    const [assignmentStates,SetAssingmentStates] = useState(0)
    const [attendanceStates,SetAttendanceStates] = useState(0)
    const [paymentStates,SetPaymentStates] = useState(0)

    // Assingments
    const [assingmentsTasks,SetAssingmentsTasks]= useState(null)

    // Next Lecture
    const [NextLectureDate,SetNextLectureDate] = useState(" - ")
    const [NextRemainingDays,SetNextRemainingDays] = useState(0)

    // The Zikr
    const [currentZikr, setCurrentZikr] = useState(0)
    const Azkar = ["سبحان الله و بحمده سبحان الله العظيم","لا اله الا الله وحده لا شريك له","استغفر الله و اتوب اليه","الله اكبر"]
    useEffect(()=>{
        const interval = setInterval(() => {
            setCurrentZikr(prevZikr => (prevZikr + 1) % Azkar.length);
          }, 10000); 
      
          // Clean up the interval on component unmount
          return () => clearInterval(interval);
    // eslint-disable-next-line
    },[])
    
    // Get All Enrolled Tracks
    useEffect(()=>{
        axios.get(public_variables.backend_url+"/tracks/enrolled_tracks?userID="+localStorage.getItem("id")).then((res)=>{

            SetTrachksDropList(res.data[0]['name'])
            setActiveTrackID(res.data[0]["id"])
            const options = []
            if (res.data.length > 1){
                for(var i=0;i<  res.data.length;i++){
                    options.push(<option  onClick={(event) => setActiveTrackID(event.currentTarget.id)} id={res.data[i]['id']}>{res.data[i]['name']}</option>)
                }
                SetTrachksDropList(<DropDownInput key ={1} placeholder={res.data[0]['name']}>
                                        {options}
                                    </DropDownInput>)
            }
        }).catch((err)=>{
            console.log("Error:",err)
            showMessage(err.response.data)
        })
    },[])

    // Get Overview Data Related To TrackID
    useEffect(()=>{
        console.log("Active: ",activeTrackID)
        if (activeTrackID){

            // Get Financials Data
            // axios.get(public_variables.backend_url+`/overview/financials?userID=${localStorage.getItem("id")}&trackID=${activeTrackID}`)
            // .then((res)=>{
            //     const data = res.data
            //     SetPaid(data['paid'])
            //     SetLate(data['late'])
            //     SetTotal(data['total'])
            //     SetPaidPercentage(data['paid'] *100/ (data['paid'] + data['late'] ) ) 

            // }).catch((err)=>{
            //     console.log("Error:",err)
            // showMessage(err.response.data)
            // })

            // Get Statistics
            axios.get(public_variables.backend_url+`/overview/statistics_overview?userID=${localStorage.getItem("id")}&trackID=${activeTrackID}`)
            .then((res)=>{
                const data = res.data
                SetAssingmentStates(data['assignments'])
                SetAttendanceStates(data['attendance'])
                // SetPaymentStates(data['payment'])
            }).catch((err)=>{
                console.log("Error:",err)
                showMessage(err.response.data)
            })

            // Get Next Lecture
            axios.get(public_variables.backend_url+`/overview/next_lecture?currentDate=${new Date()}&trackID=${activeTrackID}`)
            .then((res)=>{
                const data = res.data
                const results = formatDateWithDaysRemaining(data['nextLecture'])
                SetNextLectureDate(results.formattedDate)
                SetNextRemainingDays(results.daysRemaining)
                console.log()
            }).catch((err)=>{
                console.log("Error:",err)
                showMessage(err.response.data)

            })
            
        }
    },[activeTrackID])

    // Get Overview Data Not Related To TrackID
    useEffect(()=>{
        // Get Assingments
        axios.get(public_variables.backend_url+`/overview/assignments_overview?userID=${localStorage.getItem("id")}`)
        .then((res)=>{
            console.log(res.data)
            const data = res.data
            const TableChildren = []
            for (var i = 0 ;i<data.length ; i++){
                TableChildren.push(
                    <tr onClick={()=>{document.getElementById("todoNavButton").click()}}>
                        <td>{data[i].title}</td>
                        <td className="gray">{data[i].track}</td>
                        <td className="gray">{formattedDate(data[i]["lecture"]["date"])}</td>
                        <td>
                            <div  className="stateBox statusMiss">
                                <FontAwesomeIcon icon="fa-solid fa-minus" />
                            </div>
                        </td>
                    </tr>
                )
            }
            while (TableChildren.length < 4){
                TableChildren.push(
                    <tr onClick={()=>{document.getElementById("todoNavButton").click()}}>
                        <td>-</td>
                        <td className="gray">-</td>
                        <td className="gray">-</td>
                        <td>
                            {/* <div  className="stateBox statusMiss">
                                <FontAwesomeIcon icon="fa-solid fa-minus" />
                            </div> */}
                        </td>
                    </tr>
                )
            }
            SetAssingmentsTasks(TableChildren)
        }).catch((err)=>{
            console.log("Error:",err)
            showMessage(err.response.data)
        })
    },[])

    const [lecturesOverView,setlecturesOverView] = useState([])
    // Get lectures Data  Related To TrackID
    useEffect(()=>{
        if(activeTrackID){
        // Get Assingments
        axios.get(public_variables.backend_url+`/lectures/lectures?trackID=${activeTrackID}`)
        .then((res)=>{
            console.log(res.data)
            const data = res.data
            const TableChildren = []
            for (var i = 0 ;i<data.length ; i++){
                if(i==4){
                    break;
                }
                TableChildren.push(
                    <tr onClick={()=>{document.getElementById("lecturesNavButton").click()}}>
                        <td>{data[i].title}</td>
                        {/* <td className="gray">{data[i].track}</td> */}
                        <td className="gray">{formattedDate(data[i]["date"])}</td>
                        {/* <td>
                            <div  className="stateBox statusMiss">
                                <FontAwesomeIcon icon="fa-solid fa-minus" />
                            </div>
                        </td> */}
                    </tr>
                )
                
            }
            while (TableChildren.length < 4){
                TableChildren.push(
                    <tr onClick={()=>{document.getElementById("lecturesNavButton").click()}}>
                        {/* <td>-</td> */}
                        <td className="gray">-</td>
                        <td className="gray">-</td>
                        
                    </tr>
                )
            }
            setlecturesOverView(TableChildren)
        }).catch((err)=>{
            console.log("Error:",err)
            showMessage(err.response.data)
        })
    }
    },[activeTrackID])

    return (
        <div id="OverviewPage">
            <MainHeader title={pageText['overview']} comment={pageText['pageComment']}>
                {TracksDropList}
            </MainHeader>

            <div className="overviewBoxes">
                <div className="overviewBoxLeft">
                    {/* <OverviewBox title={pageText["f"]} className={"financialsBox"}>
                        <div className="BoxContent">
                            <div className="box-left">
                                
                                <div className="paymentCard">
                                    <div className="cardTitle">
                                        <p>مشكاة</p>
                                        <span>{pageText['MyFinance']}</span>
                                    </div>
                                    <div className="CardNumber">
                                        <p><span>0050</span> <span>****</span> <span>****</span> <span>0400</span></p>
                                    </div>
                                    <div className="cardInfo">
                                        <div className="CardHolderName">
                                            <p>{pageText["CardHolder"]}</p>
                                            <p>{localStorage.getItem("name")}</p>
                                        </div>
                                        <div className="CardExpireDate">
                                            <p>{pageText["ExpireDate"]}</p>
                                            <p>09 / 20</p>
                                        </div>
                                    </div>
                                </div>
                                
                                <GraphBar 
                                    value={PaidPercentage} 
                                    textValue = {Math.round(PaidPercentage) + " %"} 
                                    title={pageText["weeklyPayment"]} 
                                    color={"#197BBD"} 
                                    sideValue={false}/>
                            </div>
                            <hr />
                            <div className="box-right">
                                <div className="rightBoxValue">
                                    <p className="value value1"><Counter value={Paid}/></p>
                                    <span className="comment">{pageText['paid']}</span>
                                </div>
                                
                                <div  className="rightBoxValue">
                                    <p className="value value3"><Counter value={Late}/></p>
                                    <span className="comment">{pageText["yourPay"]}</span>
                                </div>
                                <div className="rightBoxValue">
                                    <p className="value value2"><Counter value={Total}/></p>
                                    <span className="comment">{pageText['Total']}</span>
                                </div>
                            </div>
                        </div>
                    </OverviewBox> */}
                    <OverviewBox title={"احدث المحاضرات"} className={"assingmentBox"}>
                        <div className="BoxContent">
                            <table>
                                <tr>
                                    <th>{pageText['name']}</th>
                                    {/* <th>{pageText["Track"]}</th> */}
                                    <th>{pageText['Date']}</th>
                                </tr>
                                {lecturesOverView}
                            </table>
                        </div>
                    </OverviewBox>
                    <OverviewBox title={pageText['assignments']} className={"assingmentBox"}>
                        <div className="BoxContent">
                            <table>
                                <tr>
                                    <th>{pageText['name']}</th>
                                    <th>{pageText["Track"]}</th>
                                    <th>{pageText['Date']}</th>
                                    <th>{pageText['status']}</th>
                                </tr>
                                {assingmentsTasks}
                            </table>
                        </div>
                    </OverviewBox>

                </div>
                <div className="overviewBoxRight">

                    <OverviewBox title={pageText['statistics']} className={"Statistics"} Borderless={true}>
                        <div className="BoxContent">
                            <div className="row">
                                <div className="IconBox IconBox1">
                                    <FontAwesomeIcon icon="fa-solid fa-clipboard-check" />
                                </div>
                                <GraphBar 
                                    value={assignmentStates} 
                                    textValue = {Math.round(assignmentStates) +"%"} 
                                    title={pageText["assignments"]} 
                                    color={"#F79042"} 
                                    sideValue={true}/>
                            </div>
                            <div className="row">
                                <div className="IconBox IconBox2">
                                    <FontAwesomeIcon icon="fa-solid fa-clipboard-user" />
                                </div>
                                <GraphBar 
                                    value={attendanceStates} 
                                    textValue = {Math.round(attendanceStates)+"%"} 
                                    title={pageText["attendance"]} 
                                    color={"#209D43"} 
                                    sideValue={true}/>
                            </div>
                            {/* <div className="row">
                                <div className="IconBox IconBox3">
                                    <FontAwesomeIcon icon="fa-solid fa-money-bill-1-wave" />
                                </div>
                                <GraphBar 
                                    value={paymentStates} 
                                    textValue = {Math.round(paymentStates) +"%"} 
                                    title={pageText["weeklyPayment"]} 
                                    color={"#70A6E8"} 
                                    sideValue={true}/>
                            </div> */}
                        </div>
                    </OverviewBox>

                    <OverviewBox title={pageText["dontForget"]} className={""} Borderless={true}>
                        <div className="BoxContent">
                            <h3>{Azkar[currentZikr]}</h3>
                        </div>
                    </OverviewBox>
                    <OverviewBox title={pageText["lectures"]} className={"nextStats"} >
                        <div className="BoxContent">
                            <p className="nextLecture">{NextLectureDate}</p>
                            <GraphBar 
                                    value={Math.round((7 - NextRemainingDays)  * 100 / 7)} 
                                    textValue = {NextRemainingDays + " ايام"} 
                                    title={pageText["remainingLecs"]} 
                                    color={"#C62DB7"} 
                                    sideValue={true}/>
                        </div>
                    </OverviewBox>
                    
                </div>
            </div>
        </div>
    );
}

export default OverviewPage;
