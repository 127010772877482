
import "./Tracks.css"
import MainHeader from "../../components/header";
import { useEffect, useState } from "react";
import axios from "axios";
import public_variables from "../../publicVar";
import TrackBox from '../../components/TrackBox'
import { useSideMessage } from "../../components/Error/SideMessageContext";
function TracksPage() {
    // Error Message 
    const { showMessage } = useSideMessage();

    const lang = localStorage.getItem("lang")
    const pageText = {
        "title":      lang === "english" ? "Tracks":"المسارات",
        "comment":    lang === "english" ? "Take a Look at Registered Tracks":"الق نظره علي المسارات المسجلة",
        "enrolled":   lang === "english" ? "Enrolled":"المسجلة",
        "enroll":     lang === "english" ? "Enroll":"تسجيل",
        "lectures":   lang === "english" ? "Lectures":"المحاضرات",
        "lecture":    lang === "english" ? "Lecture":"محاضرة",
        "openForReg": lang === "english" ? "Open For Registration":"متاح للتسجيل",
        "LectureDay":   lang === "english" ? "Expected Day":"اليوم المتوقع "
        

    }

    // Enrolled and Open Tracks Tracks
    const [enrolled,setEnrolled] = useState([])

    useEffect(()=>{
        
        // Get Enrolled Tracks
        axios.get(public_variables.backend_url+"/tracks/enrolled_tracks?userID="+localStorage.getItem("id")).then((res)=>{
            const data = res.data
            const temp = []
            for(var i = 0;i<data.length;i++){
                temp.push(
                    <TrackBox
                    id={data[i]['id']}
                    title={data[i]['name']}
                    description={data[i]['description']}
                    imgLink={data[i]['imageLink']}
                    lectureCount={data[i]['lecturesCount']}
                    />
                )

            }
            setEnrolled(temp)
        }).catch((err)=>{
            console.log("Error:",err)
            showMessage(err.response.data)
    })

    },[])
    return (
        <div id="TracksPage">
            <MainHeader title={pageText["title"]} comment={pageText['comment']}/>
            <div className="TracksContainer">
                <h3 className="sectionTitle">{pageText["enrolled"]}</h3>
                <div className="BoxesContainer">

                    {enrolled}
                    
                </div>
                
            </div>
        </div>  
    );
}

export default TracksPage;
