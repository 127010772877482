import axios from "../../functions/axiosAuth"
import InputField from "../../components/Input";
import background from "../../content/Login.png"
import logoImage from "../../content/logo.png"
import public_variables from "../../publicVar";

import "./AdminRegister.css"
import { useState } from "react";
import DropDownInput from "../../components/DropDownInput";
import { useParams } from "react-router";
import {validateEmail,validatePhone} from "../../functions/validate";
import checkLoggedIn from "../../functions/checkLogin";
import { useSideMessage } from "../../components/Error/SideMessageContext";

function AdminRegisterPage() {


  // Error Message 
  const { showMessage } = useSideMessage();

  // Massage
  const [message,setMessage] = useState(null)
  const [isError,setIsError] = useState(false)
  
  // Gender Input and Token
  const [gender,setGender]  = useState(null)
  const {token} = useParams()

  // Check if Already Logged-in, Redirect to home ./
  checkLoggedIn(`/adassign/${token}`)

  // Send Data To API
  function submitTheRegisterForm(event){
    const name = document.getElementById("nameInput").querySelector("input").value
    // const username = document.getElementById("userNameInput").querySelector("input").value
    const password = document.getElementById("passwordInput").querySelector("input").value
    const repassword = document.getElementById("repasswordInput").querySelector("input").value
    const email = document.getElementById("emailInput").querySelector("input").value
    const phone = document.getElementById("phoneInput").querySelector("input").value
    // Form Validation
    if(
      name === "" ||
      password === "" ||
      phone === "" ||
      !gender
    ){
      setIsError(true)
      setMessage("برجاء ملئ الخانات بالاسفل")
      return
    }else if(password.length <=6){
      setIsError(true)
      setMessage("كلمة السر يجب ان تكون اطول من 6 احرف")
      return
    }else if(password !== repassword){
      setIsError(true)
      setMessage("كلمتا السر غير متطابقتين")
      return
    }else if( email !=="" && !validateEmail(email)){
      setIsError(true)
      setMessage("البريد الألكتروني غير صحيح")
      return
    }else if(!validatePhone(phone)){
      setIsError(true)
      setMessage("رقم الهاتف غير صحيح")
      return
    }

    // Clear the Message
    setIsError(false)
    setMessage(null)
    const reqData = {
        token: token,
        password: password,
        email: email === ''? null:email,
        name: name,
        phone: phone,
        gender: gender
      }
    
    axios.post(public_variables.backend_url+"/admin/signup",reqData).then(res=>{
      setIsError(false)
      setMessage("يتم التسجيل ")

      window.location.href = "/login"

    }).catch((err)=>{
      setIsError(true)
      setMessage(err.response.data)
      console.log("Error!!",err)
      showMessage(err.response.data)
    })
  }


  // Handle From With Enter Key
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default form submission behavior
      submitTheRegisterForm();
    }
  };
  function goToLogin(){
    localStorage.setItem("redirected",`/adassign/${token}`)
    window.location.href = '/login'
  }
  return (
    <div id="AdminRegisterPage">
      {/* <img src={background} className="background" /> */}
      <div className="registerSection">
        <div className="logoImageContainer">
          <img src={logoImage} className="logoImage"/>
        </div>
        <div className="registerFormContainer">
          <div className="registerform">
            <h1 className="formTitle">اهلا بك في مشكاة</h1>
            <h3>قم بانشاء حساب كمشرف</h3>
            <span className={isError?"errorMessage":"correctMessage"}>{message}</span>
            <p className="ErrorMessage"></p>
            <InputField type={"text"} id={"nameInput"} name={"name"} placeholder={"الاسم (يفضل بالعربي)"}  className={"usernameFiled"} onKeyDown={handleKeyDown} required={true}/>
            {/* <InputField type={"text"} id={"userNameInput"} name={"usename"} placeholder={"الأسم المستخدم لتسجيل الدخول"} className={"usernameFiled"} onKeyDown={handleKeyDown}/> */}
            <InputField type={"number"} id={"phoneInput"} name={"phone"} placeholder={"رقم الهاتف"}  className={"usernameFiled"} onKeyDown={handleKeyDown} required={true}/>
            <InputField type={"email"} id={"emailInput"} name={"email"} placeholder={"البريد الألكتروني"}  className={"usernameFiled"} onKeyDown={handleKeyDown}/>
            <InputField type={"password"} id={"passwordInput"} name={"password"} placeholder={"كلمة السر"}  className={"passwordFiled"} onKeyDown={handleKeyDown} required={true}/>
            <InputField type={"password"} id={"repasswordInput"} name={"repassword"} placeholder={"اعد كتابةكلمة السر"}  className={"passwordFiled"} onKeyDown={handleKeyDown} required={true}/>

            <div  id="genderInput" class="form__group">
              <p className="grayComment">الجنس: </p>
              <div class="form__radio_group">
                <input id="Male" type="radio" class="form__radio-input" name="gender" onClick={()=>{setGender('male')}}/>
                <label for="Male" class="form__radio-label">
                  <span class="form__radio-button"></span>
                  <span class="form__radio-label-text">ذكر</span>

                  </label>
              </div>

              <div class="form__radio_group">
                <input id="Female" type="radio" class="form__radio-input" name="gender" onClick={()=>{setGender("female")}} />
                <label for="Female" class="form__radio-label">
                  <span class="form__radio-button"></span>
                  <span class="form__radio-label-text">أنثي</span>
                </label>
              </div>
            </div>
            <button onClick={submitTheRegisterForm}>انشاء حساب</button>
            <a onClick={goToLogin} className="alreadyHaveAccount">املك حساب بالفعل</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminRegisterPage;
