import axios from "axios";
import InputField from "../../components/Input";
import background from "../../content/Login.png"
import logoImage from "../../content/logo.png"
import public_variables from "../../publicVar";

import "./Forget.css"
import { useState } from "react";
import {validateEmail} from "../../functions/validate";
import checkLoggedIn from "../../functions/checkLogin";
import { useSideMessage } from "../../components/Error/SideMessageContext";

function ForgetPage() {
  // Check if Already Logged-in, Redirect to home ./
  checkLoggedIn()

  // Error Message 
  const { showMessage } = useSideMessage();

  // Massage
  const [message,setMessage] = useState(null)
  const [isError,setIsError] = useState(false)

  function submitTheLoginForm(even){
    const email = document.getElementById("emailInput").querySelector("input").value
    if(email === ""){
      setIsError(true)
      setMessage("برجاء كتابة الايميل")
      return
    }else if(!validateEmail(email)){
      setIsError(true)
      setMessage("الأيميل غير صحيح")
      return 
    }
    const reqData = {
      email: email
    }
    axios.post(public_variables.backend_url+"/auth/send_email",reqData).then(res=>{
      localStorage.setItem("tempEmail",email)
      setIsError(false)
      setMessage("يتم التحويل ")

      window.location.href = "/reset"
    }).catch(err=>{
      setIsError(true)
      setMessage(err.response.data)
      console.log("Error!!")
      showMessage(err.response.data)

    })
  }

  // Handle From With Enter Key
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default form submission behavior
      submitTheLoginForm();
    }
  };
  return (
    <div id="ForgetPage">
      <img src={background} className="background" />
      <div className="loginSection">
        <div className="logoImageContainer">
          <img src={logoImage} className="logoImage"/>
        </div>
        <div className="loginFormContainer">
          <div className="loginform">
            <h1 className="formTitle">اهلا بك في مشكاة</h1>
            <h3>ارسال رمز لتغيير كلمة المرور</h3>
            <span className={isError?"errorMessage":"correctMessage"}>{message}</span>
            <InputField type={"email"} id={"emailInput"} name={"email"} placeholder={"البريد الإلكتروني للاسترداد"} className={"usernameFiled"} onKeyDown={handleKeyDown}/>
            <a href="/login" className="forgetPassword">تذكرت كلمة السر؟</a>
            <button onClick={submitTheLoginForm}>ارسال رمز التاكيد</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgetPage;
