import React, { createContext, useState, useContext } from 'react';

const SideMessageContext = createContext();

export const useSideMessage = () => useContext(SideMessageContext);

export const SideMessageProvider = ({ children }) => {
    const [messages, setMessages] = useState([]);

    const showMessage = (message, type = 'error') => {
        const id = Date.now();
        setMessages((prevMessages) => [...prevMessages, { id, message, type }]);

        setTimeout(() => {
            setMessages((prevMessages) => prevMessages.filter(msg => msg.id !== id));
        }, 10000); // Hide after 10 seconds
    };

    return (
        <SideMessageContext.Provider value={{ messages, showMessage }}>
            {children}
        </SideMessageContext.Provider>
    );
};
