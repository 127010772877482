
import "./SuperAdminTracks.css"
import MainHeader from "../../components/header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputField from "../../components/Input";
import { useEffect, useState } from "react";
import axios from "axios";
import public_variables from "../../publicVar";
import DropDownInput from "../../components/DropDownInput";
import SuperTrackBox from "../../components/SuperTrackBox";
import { useSideMessage } from "../../components/Error/SideMessageContext";
function SuperAdminTracksPage() {
    // Error Message 
    const { showMessage } = useSideMessage();
    const lang = localStorage.getItem("lang")
    const pageText = {
        "title":      lang === "english" ? "Tracks":"المسارات",
        "addNew":     lang === "english" ? "Add New Track":"أضف مسار جديد",
        "enrolled":   lang === "english" ? "Enrolled":"مسارات تم غلق االتسجيل فيها",
        "open":       lang === "english" ? "Open Enrollment":"فتح باب التسجيل ليوم",
        "close":     lang === "english" ? "Close Enrollment":"غلق باب التسجيل",
        "openForReg": lang === "english" ? "Open For Registration":"متاح للتسجيل",
        "firstLec":   lang === "english" ? "First Lecture at":"اول محاضرة في",
        "edit":       lang === "english" ? "Add New":"اضافة",
        "TrackName": lang === "english" ? "Track Name":"اسم المسار",
        "TrackDesc": lang === "english" ? "Track Description":"وصف المسار",
        "lectureNum": lang === "english" ? "Number of Lectures":"عدد المحاضرات",
        "imageLink":  lang === "english" ? "Track Cover Image":"رابط لصورة المسار",
        "expensis":   lang === "english" ? "Lecture Price":"سعر المحاضرة",
        "day":        lang === "english" ? "Track Cover Image":"اليوم الاسبوعي للمسار",
        "deadline":   lang === "english" ? "Track Cover Image":"اخر ميعاد للتسجيل",
    }

    function openForm(event){
        document.getElementById("SuperAdminTrackssPage")
        .querySelector(".changeFromContainer .backgroundBlock")
        .style.display = "block"
        document.getElementById("SuperAdminTrackssPage")
        .querySelector(".changeFromContainer")
        .style.zIndex = "999"
        document.getElementById("SuperAdminTrackssPage")
        .querySelector(".changeFromContainer .ChangingForms").classList.add("shown")
        SetMessage(null)
    }
    function closeForm(event){
        document.getElementById("SuperAdminTrackssPage")
        .querySelector(".changeFromContainer .backgroundBlock")
        .style.display = "none"
        document.getElementById("SuperAdminTrackssPage")
        .querySelector(".changeFromContainer .ChangingForms").classList.remove("shown")
        document.getElementById("SuperAdminTrackssPage")
        .querySelector(".changeFromContainer")
        .style.zIndex = "-1"
    }
   

    // Assinged List
    const [passedTracks,setPassedTracks] = useState([])
    const [notPassedTracks,setNotPassedTracks] = useState([])

    // Messages
    const [message,SetMessage] = useState(null)
    const [messageType,SetMessageType] = useState(null)

    // Get  Tracks
    useEffect(()=>{
        axios.get(public_variables.backend_url+`/super-admin/tracks?adminID=${localStorage.getItem("id")}`)
        .then((res)=>{
            const data = res.data
            const tempOpen = []
            const tempClose = []
            for(var i = 0;i<data.length;i++){
                const deadline = new Date(data[i]['EnrollmentDeadline']) 
                const now = new Date()
                const passed = deadline.getTime() - now.getTime()
                const element = <SuperTrackBox 
                                id={data[i]['id']}
                                title={data[i]['name']}
                                description={data[i]['description']}
                                imgLink={data[i]['imageLink']}
                                lectureCount={data[i]['lecturesCount']}
                                studentsCount={data[i]['studentCount']}
                                enrollmentToken={data[i]['enrollmentToken']}
                                WeeklyDay={data[i]['lectureDay']}
                                deadline={data[i]['EnrollmentDeadline']}
                                dayTime={data[i]['lectureTime']}
                                passed={passed}
                                />
                if (passed<=0){
                    tempOpen.push(element)
                }else{
                    tempClose.push(element)
                }


            }

            // Add Empty Message
            if(!tempOpen.length){
                setPassedTracks(<span className="emptyMessage">لا توجد مسارات</span>)
            }else{
                setPassedTracks(tempOpen)
            }

            if(!tempClose.length){
                setNotPassedTracks(<span className="emptyMessage">لا توجد مسارات</span>)
            }else{
                setNotPassedTracks(tempClose)
            }
        }).catch((err)=>{
            console.log("Error!",err)
            showMessage(err.response.data)
        })
    },[])

    // Add New Track
    function AddNewTrack(event){
        const trackName = document.getElementById("TrackNameInput").querySelector("input").value
        const trackDescription = document.getElementById("TrackDescriptionInput").querySelector("input").value
        const trackLecCount = document.getElementById("TrackLecturesInput").querySelector("input").value
        const trackDeadline = document.getElementById("trackDeadlineInput").querySelector("input").value
        const trackCoverImage = document.getElementById("TrackImageLinkInput").querySelector("input").value
        var lectureTime = document.querySelector("#trackLectureTime input").value
        console.log(lectureTime)

        const Days = {
            "السبت": "Saturday",
            "الأحد": "Sunday",
            "الاثنين": "Monday",
            "الثلاثاء": "Tuesday",
            "الأربعاء": "Wednesday",
            "الخميس": "Thursday",
            "الجمعة": "Friday"
        };
        const trackDayInput = document.getElementById("trackDayInput").querySelector(".selected").innerHTML
        const trackDay = Days[trackDayInput]
        // Check Empty 
        if (
            trackName === "" ||
            trackDescription === "" ||
            trackLecCount === "" ||
            trackDay === undefined ||
            trackDeadline === "" ||
            !lectureTime ||
            trackCoverImage === "" 
        ){
            SetMessage("يجب ملئ الخانات بالاسفل")
            SetMessageType("error")
            return;
        }

        // Fix Time Date
        if(Number(lectureTime.split(":")[0])>12){
            lectureTime =`${Number(lectureTime.split(":")[0])-12}:${lectureTime.split(":")[1]}PM`
        }else{
            lectureTime =`${Number(lectureTime.split(":")[0])}:${lectureTime.split(":")[1]}AM`
        }

        // Clear the Message
        SetMessage(null)

        // Send to API
        const reqData = {
            name: trackName,
            lecturesCount: trackLecCount,
            description:trackDescription,
            imageLink:trackCoverImage,
            lectureDay: trackDay,
            EnrollmentDeadline: trackDeadline,
            lectureTime:lectureTime,
            currentDate:new Date()
         }
        axios.post(public_variables.backend_url+"/super-admin/new_track",reqData).then((res)=>{
            console.log(res.data)
            SetMessage("تمت اضافة المسار")
            SetMessageType("correct")
            window.location.reload()
        }).catch((err)=>{
            console.log("Error!!",err)
            showMessage(err.response.data)
        })

    }

    return (
        <div id="SuperAdminTrackssPage">
            <MainHeader title={pageText['title']} comment={""}/>
            <div className="TracksContainer">
                <div className="AddNewButtonsContainer">
                    <button className="AddNewButtons" onClick={openForm}><FontAwesomeIcon icon="fa-solid fa-plus" />  {pageText['addNew']}</button>
                </div>
                <h3 className="sectionTitle">{pageText['enrolled']}</h3>
                <div className="BoxesContainer">
                    {passedTracks}
                </div>
                <h3 className="sectionTitle">{pageText['openForReg']}</h3>
                <div className="BoxesContainer">
                    {notPassedTracks}

                </div>
                

                <div className="changeFromContainer">
                    <div className="changeFromContainer">
                        <div className="backgroundBlock" onClick={closeForm}></div>
                        <div className="ChangingForms">
                            <h2>{pageText['addNew']}</h2>
                            <span className={messageType+"Message"}>{message}</span>
                            <InputField type={"text"}   id={"TrackNameInput"}  className={"chageFormInput"} name={"TrackName"} placeholder={pageText['TrackName']}/>
                            <InputField type={"text"}   id={"TrackDescriptionInput"}  className={"chageFormInput"} name={"TrackDescription"} placeholder={pageText['TrackDesc']}/>
                            <InputField type={"number"} id={"TrackLecturesInput"}  className={"chageFormInput"} name={"TrackLectures"} placeholder={pageText['lectureNum']}/>
                            <DropDownInput id={"trackDayInput"} classList={"chageFormInput"} placeholder={pageText['day']}>
                                <option>السبت</option>
                                <option>الأحد</option>
                                <option>الاثنين</option>
                                <option>الثلاثاء</option>
                                <option>الأربعاء</option>
                                <option>الخميس</option>
                                <option>الجمعة</option>
                            </DropDownInput>
                            <InputField type={"time"}   id={"trackLectureTime"}  className={"chageFormInput"} name={"trackLectureTime"} placeholder={"المعاد المتوقع للمحاضرات"}/>
                            <InputField type={"date"}   id={"trackDeadlineInput"}  className={"chageFormInput"} name={"TrackDeadline"} placeholder={pageText['deadline']}/>
                            <InputField type={"text"}   id={"TrackImageLinkInput"}  className={"chageFormInput"} name={"TrackImageLink"} placeholder={pageText['imageLink']}/>

                            <button onClick={AddNewTrack}>{pageText['edit']}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    );
}

export default SuperAdminTracksPage;
