  // Form Input Validation Function
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const validatePhone = (phone) => {
    const phoneRegex = /^(011|012|010|015)\d{8}$/;
    return phoneRegex.test(phone);
  };



export { validateEmail, validatePhone };
