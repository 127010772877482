import React from 'react';
import SideNavigation from './SideNavFunction';
import SideNavigationButton from './SideNavButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function SuperAdminSideNavBar() {

  const navList = ['overview',"attendance","tracks","lectures","financials","admins","students","settings"] 
  const defualtRoute = "tracks"
    const lang =  localStorage.getItem('lang') 
    const compText = {
        // overview:      lang === 'en' ? "Overview":"الرئيسية",
        attendance:    lang === 'english' ? "Attendance":"الحضور",
        admins:        lang === 'english' ? "Admins":"المشرفين",
        students:      lang === 'english' ? "Students":"الطلاب",
        tracks:        lang === 'english' ? "Tracks":"المسارات",
        supertracks:   lang === 'english' ? "Super Tracks":"المسارات",
        lectures:      lang === 'english' ? "Lectures":"المحاضرات",
        financials:    lang === 'english' ? "Financials":"المصاريف",
        settings:      lang === 'english' ? "Settings":"الاعدادات"
    }
    function showHideMinu(){
        if(lang === "english"){
            document.getElementsByClassName('sideNav')[0].style.setProperty('left','0px')
            document.querySelector('.sideNav').querySelector('.backgroundBlock').style.left = document.getElementsByClassName('sideNav')[0].offsetWidth + "px"

        }else{
            document.getElementsByClassName('sideNav')[0].style.setProperty('right','0px')
            document.querySelector('.sideNav').querySelector('.backgroundBlock').style.left = "-"+document.getElementsByClassName('sideNav')[0].offsetWidth + "px"

        }
        
        document.querySelector('.sideNav').querySelector('.backgroundBlock').style.setProperty('display','block')
    }
return (
    <div>
        <div className="sideMinu row">
            <FontAwesomeIcon icon="fa-solid fa-list" onClick={showHideMinu} />   
        </div>
        <SideNavigation defualtRoute={defualtRoute} navList={navList}>
            <SideNavigationButton route={"tracks"} text={compText["supertracks"]} icon={<FontAwesomeIcon icon="fa-solid fa-book" />}/>
            <SideNavigationButton route={"lectures"} text={compText["lectures"]} icon={<FontAwesomeIcon icon="fa-solid fa-chalkboard-user" />}/>
            <SideNavigationButton route={"attendance"} text={compText["attendance"]} icon={<FontAwesomeIcon icon="fa-solid fa-user-check" />}/>
            <SideNavigationButton route={"financials"} text={compText["financials"]} icon={<FontAwesomeIcon icon="fa-solid fa-money-bill" />}/>
            <SideNavigationButton route={"admins"} text={compText["admins"]} icon={<FontAwesomeIcon icon="fa-solid fa-user-tie" />}/>
            <SideNavigationButton route={"students"} text={compText["students"]} icon={<FontAwesomeIcon icon="fa-solid fa-graduation-cap" />}/>
            <SideNavigationButton route={"settings"} text={compText["settings"]} icon={<FontAwesomeIcon icon="fa-solid fa-gear" />}/>
        </SideNavigation>
    </div>
)
}

export default SuperAdminSideNavBar;
