const public_variables = {
    fronend_url: "https://mishkahproject.com",         // Front-end Link
    backend_url: "https://mishkahproject.com/backapi",         // Back-end Link
    trackInfo: "/trackinfo/",
    user_registraion:"/register/",             // Front-end Endpoint for User Registration
    user_signup:"/signup/",             // Front-end Endpoint for User Signup
    admin_registraion:"/adregister/",             // Front-end Endpoint for Admin Registration
    encryptionKeyString:"helpqaoertxzfgwortabfgeowprtwael"
}


export default public_variables;
