
import "./FormResponses.css"
import MainHeader from "../../components/header";
import Counter from "../../components/Counter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropDownInput from "../../components/DropDownInput";
import { useEffect, useState } from "react";
import axios from "axios";
import public_variables from "../../publicVar";
import { useParams } from "react-router";
import formatDate from '../../functions/DateFix'
import { useSideMessage } from "../../components/Error/SideMessageContext";
function FormResponsesPage() {
    // Error Message 
    const { showMessage } = useSideMessage();
    const {trackID} = useParams()


    const [waitingListStatus,setWitingListStatus] = useState(true)
    const [accpetedListStatus,setAcceptedListStatus] = useState(true)
    const [rejectedListStats,setRejectedListStatus] = useState(true)
    const [waitingList,setWaitingList] = useState([])
    const [accpetedList,setAcceptedList] = useState([])
    const [rejectedList,setRejectedList] = useState([])
    const [newData,setNewData] = useState(false)

    // Choose Active Section to View Data
    function selectActiveSection(event){
        document.querySelector("#FormResponsesPage .ContainerMiniNav .activeSection").classList.remove("activeSection")
        const currentElmnt = event.currentTarget
        currentElmnt.classList.add("activeSection")
        if (currentElmnt.classList.contains('all')){
            setWitingListStatus(true)
            setAcceptedListStatus(true)
            setRejectedListStatus(true)
        }else if(currentElmnt.classList.contains('waiting')){
            setWitingListStatus(true)
            setAcceptedListStatus(false)
            setRejectedListStatus(false)
        }else if (currentElmnt.classList.contains('accpeted')){
            setWitingListStatus(false)
            setAcceptedListStatus(true)
            setRejectedListStatus(false)
        }else if (currentElmnt.classList.contains('rejected')){
            setWitingListStatus(false)
            setAcceptedListStatus(false)
            setRejectedListStatus(true)
        }
        
    } 

    // accept a User
    function AccpetRegisteration(event){
        const registerID = event.currentTarget.closest("tr").id.replace("reg",'')
        const reqData={
            registerID:registerID,
            accept:true
        }
        axios.post(public_variables.backend_url+`/super-admin/registration_desicion`,reqData).then(res=>{
            console.log("Accepted")
            setNewData(!newData)
        }).catch(err=>{
            console.log("Error!",err)
            showMessage(err.response.data)
        })
    }

    // Reject a User
    function RejectRegisteration(event){
        const registerID = event.currentTarget.closest("tr").id.replace("reg",'')
        const reqData={
            registerID:registerID,
            accept:false
        }
        axios.post(public_variables.backend_url+`/super-admin/registration_desicion`,reqData).then(res=>{
            console.log("Done")
            setNewData(!newData)

        }).catch(err=>{
            console.log("Error!",err)
            showMessage(err.response.data)
        })
    }

    // Get Lectures ID's
    useEffect(()=>{
        axios.get(public_variables.backend_url+`/super-admin/reg_form_responses?trackID=${trackID}`).then((res)=>{
            console.log("Data: ",res.data)
            const data = res.data
            setWaitingList(data.filter(student=>{return student.accepted ===null}))
            setAcceptedList(data.filter(student=>{return student.accepted ===true}))
            setRejectedList(data.filter(student=>{return student.accepted ===false}))
        }).catch((err)=>{
            console.log("Error:",err)
            showMessage(err.response.data)
        })

    },[newData])

    return (
        <div id="FormResponsesPage">
            <MainHeader title={'نتائج الاستمارة'} comment={`لمسار: ${localStorage.getItem("info_selectedTrackName")}`}/>
            <div className="RegistrationContainer">
                <div className="ContainerMiniNav">
                    <button className="activeSection all" onClick={selectActiveSection}>الكل <span className="sectionNumbers">{waitingList.length+accpetedList.length+rejectedList.length}</span></button>
                    <button className="waiting" onClick={selectActiveSection}>لم يحدد <span className="sectionNumbers">{waitingList.length}</span></button>
                    <button className="accpeted" onClick={selectActiveSection}>المقبولين <span className="sectionNumbers">{accpetedList.length}</span></button>
                    <button className="rejected" onClick={selectActiveSection}>المرفوضين <span className="sectionNumbers">{rejectedList.length}</span></button>
                </div>
                <table>
                    <tr>
                        <th>الاسم</th>
                        <th>الجنس</th>
                        <th>الايميل</th>
                        <th>رقم الهاتف</th>
                        <th>طالب عندنا</th>
                        <th> تاريخ التسجيل </th>
                        <th> الحالة </th>
                    </tr>
                    {waitingList && waitingListStatus?waitingList.map(registration=>{
                        return  <tr key={registration.id} id={`reg${registration.id}`}>
                                    <td className="regID hide">{}</td>
                                    <td>{registration.full_name}</td>
                                    <td>{registration.gender === "female"?"أنثي":"ذكر"}</td>
                                    <td>{registration.email?registration.email:" - "}</td>
                                    <td>{registration.phone?registration.phone:" - "}</td>
                                    <td>{registration.has_account?<FontAwesomeIcon icon="fa-solid fa-check" />:<FontAwesomeIcon icon="fa-solid fa-xmark" />}</td>
                                    <td>{formatDate(registration.date)}</td>
                                    <td className="statusColumn">
                                        <button className="acceptButton" onClick={AccpetRegisteration}>قبول</button>
                                        <button className="rejectButton" onClick={RejectRegisteration}>رفض</button>
                                    </td>
                                </tr>
                    }):''}
                   {accpetedList && accpetedListStatus?accpetedList.map(registration=>{
                        return  <tr key={registration.id} id={`reg${registration.id}`}>
                                    <td className="regID hide">{registration.id}</td>
                                    <td>{registration.full_name}</td>
                                    <td>{registration.gender === "female"?"أنثي":"ذكر"}</td>
                                    <td>{registration.email?registration.email:" - "}</td>
                                    <td>{registration.phone?registration.phone:" - "}</td>
                                    <td>{registration.has_account?<FontAwesomeIcon icon="fa-solid fa-check" />:<FontAwesomeIcon icon="fa-solid fa-xmark" />}</td>
                                    <td>{formatDate(registration.date)}</td>
                                    <td className="statusColumn accepted">مقبول</td>

                                </tr>
                    }):''}
                    {rejectedList&& rejectedListStats?rejectedList.map(registration=>{
                        return  <tr key={registration.id} id={`reg${registration.id}`}>
                                    <td className="regID hide">{registration.id}</td>
                                    <td>{registration.full_name}</td>
                                    <td>{registration.gender === "female"?"أنثي":"ذكر"}</td>
                                    <td>{registration.email?registration.email:" - "}</td>
                                    <td>{registration.phone?registration.phone:" - "}</td>
                                    <td>{registration.has_account?<FontAwesomeIcon icon="fa-solid fa-check" />:<FontAwesomeIcon icon="fa-solid fa-xmark" />}</td>
                                    <td>{formatDate(registration.date)}</td>
                                    <td className="statusColumn rejected">مرفوض</td>
                                </tr>
                    }):''}
                    
                </table>
                {(!accpetedList.length && accpetedListStatus && !rejectedListStats && !waitingListStatus) ||
                 (!rejectedList.length && rejectedListStats && !accpetedListStatus && !waitingListStatus) ||
                 (!waitingList.length && waitingListStatus && !accpetedListStatus && !rejectedListStats) ||
                 (!waitingList.length && !accpetedList.length && !rejectedList.length)?
                <div className="emptyPage">
                    <span>لا يوجد نتائج</span>
                </div>:null
                }
            
            </div>
        </div>
    );
}

export default FormResponsesPage;
