
import "./Attendance.css"
import MainHeader from "../../components/header";
import Counter from "../../components/Counter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import public_variables from "../../publicVar";

import { useEffect, useState } from "react";
import axios from "axios";
import DropDownInput from "../../components/DropDownInput";
import formatDateWithDaysRemaining from "../../functions/NextData";
import { useSideMessage } from "../../components/Error/SideMessageContext";

function AttendancePage() {
     // Error Message 
     const { showMessage } = useSideMessage();

    const lang = localStorage.getItem("lang")
    const pageText = {
        "title":          lang === "english" ? "Attendance": "الحضور",
        "comment":        lang === "english" ? "Take a Look at Your Attendance Record For Track": "الق نظرة علي سجل الحضور الخاص بك في مسار",
        "statusPresent":  lang === "english" ? "Present": "حاضر",
        "statusLate":     lang === "english" ? "Late": "متاخر",
        "statusAbsent":   lang === "english" ? "Absent": "غائب",
        "statusExcuse":   lang === "english" ? "Excuse": "عذر",
        "Times":          lang === "english" ? "Times": "مرة",
        "LectureName":    lang === "english" ? "Lecture Name": "اسم المحاضرة",
        "Date":           lang === "english" ? "Date": "التاريخ",
        "Status":         lang === "english" ? "Status": "الحالة",
        
    }
    // Active Track 
    const [TracksDropList,SetTrachksDropList] = useState(" - ")
    const [activeTrackID,setActiveTrackID] = useState(null)
    
    // Attendance Variables
    const [attendanceSheet,setAttendanceSheet] = useState([])
    const [attendedCount,setAttendedCount] = useState(0)
    const [lateCount,setLateCount] = useState(0)
    const [absentCount,setAbsentCount] = useState(0)
    const [excuseCount,setExcuseCount] = useState(0)


    // Get All Enrolled Tracks
    useEffect(()=>{
        axios.get(public_variables.backend_url+"/tracks/enrolled_tracks?userID="+localStorage.getItem("id")).then((res)=>{

            SetTrachksDropList(res.data[0]['name'])
            setActiveTrackID(res.data[0]["id"])
            const options = []
            if (res.data.length > 1){
                for(var i=0;i<  res.data.length;i++){
                    options.push(<option  onClick={(event) => setActiveTrackID(event.currentTarget.id)} id={res.data[i]['id']}>{res.data[i]['name']}</option>)
                }
                SetTrachksDropList(<DropDownInput key ={1} placeholder={res.data[0]['name']}>
                                        {options}
                                    </DropDownInput>)
            }
        }).catch((err)=>{
            console.log("Error:",err)
            showMessage(err.response.data)

        })
    },[])

    // Get The Attendance From API
    useEffect(()=>{
        // Check if there is Active Track
        if (activeTrackID){

            // Get The Attendance Sheet
            axios.get(public_variables.backend_url+`/attendance/attendance?userID=${localStorage.getItem("id")}&trackID=${activeTrackID}`).then((res)=>{
                console.log(res.data)
                const data = res.data
                const temp = []
                var attended =0,late = 0,absent = 0, excuse = 0
                var status;
                for (var i=0;i<data.length;i++){

                    // Check Student Lecture Status and Count, 0 for Absent, 1 for Attended, 2 for attended Late
                    if (data[i]["attended"] === 0){
                        status = "statusAbsent"
                        absent+=1
                    }else if(data[i]["attended"] === 1){
                        status = "statusPresent"
                        attended+=1
                    }else if (data[i]["attended"] === 2){
                        status = "statusLate"
                        late+=1
                    }else{
                        status = "statusExcuse"
                        excuse+=1
                    }

                    
                    // Fix the Date
                    const date = formatDateWithDaysRemaining(data[i]['date']).miniformattedDate

                    // Adding to Sheet List
                    temp.push(
                        <tr>
                            <td>{data[i]['title']}</td>
                            <td className="gray">{date}</td>
                            <td>
                                <div className={"stateBox "+ status}>
                                    <span>{pageText[status]}</span>
                                </div>
                            </td>
                        </tr>
                    )


                }
                setAttendanceSheet(temp)
                setAttendedCount(attended)
                setLateCount(late)
                setAbsentCount(absent)
                setExcuseCount(excuse)
            }).catch((err)=>{
                console.log("Error:",err)
                showMessage(err.response.data)
            })
        }

    },[activeTrackID])
    
    return (
        <div id="AttendancePage">
            {/* <MainHeader title={pageText["title"]} comment={pageText['comment']}/> */}
            <MainHeader title={pageText['title']} comment={pageText['comment']}>
                {TracksDropList}
            </MainHeader>
            <div className="AttendanceContainer">
                <div className="NumbersBoxes">
                    <div className="NumbersBox NumberBox1">
                        <div className="icon">
                            <FontAwesomeIcon icon="fa-solid fa-clock" />
                        </div>
                        <h2>{pageText['present']}</h2>
                        <span><Counter value={attendedCount} speed={5000}/> &nbsp;{pageText['Times']}</span>
                    </div>
                    <div className="NumbersBox NumberBox2">
                        <div className="icon">
                            <FontAwesomeIcon icon="fa-solid fa-clock" />
                        </div>
                        <h2>{pageText['Late']}</h2>
                        <span><Counter value={lateCount} speed={5000}/> &nbsp;{pageText['Times']}</span>
                    </div>
                    <div className="NumbersBox NumberBox3">
                        <div className="icon">
                            <FontAwesomeIcon icon="fa-solid fa-clock" />
                        </div>
                        <h2>{pageText['Absent']}</h2>
                        <span><Counter value={absentCount} speed={5000}/> &nbsp;{pageText['Times']}</span>
                    </div>
                    <div className="NumbersBox NumberBox4">
                        <div className="icon">
                            <FontAwesomeIcon icon="fa-solid fa-clock" />
                        </div>
                        <h2>{pageText['Excuse']}</h2>
                        <span><Counter value={excuseCount} speed={5000}/> &nbsp;{pageText['Times']}</span>
                    </div>
                </div>
                <table>
                    <tr>
                        <th>{pageText['LectureName']}</th>
                        <th>{pageText["Date"]}</th>
                        <th>{pageText['Status']}</th>
                    </tr>
                    {attendanceSheet}
                </table>
            </div>
        </div>
    );
}

export default AttendancePage;
