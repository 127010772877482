
import "./Lectures.css"
import MainHeader from "../../components/header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropDownInput from "../../components/DropDownInput";
import { useEffect, useState } from "react";
import axios from "axios";
import public_variables from "../../publicVar";
import formatDateWithDaysRemaining from "../../functions/NextData";
import { useSideMessage } from "../../components/Error/SideMessageContext";
function LecturesPage() {
    // Error Message 
    const { showMessage } = useSideMessage();

    const lang = localStorage.getItem("lang")
    const pageText = {
        "title":      lang === "english" ? "Lectures":"المحاضرات",
        "comment":    lang === "english" ? "Take a Look at Your Lectures and Materials":"الق نظره علي المهام و المواد العلمية",
        "myLec":      lang === "english" ? "My Lectures":"محاضراتي",
        "select":     lang === "english" ? "Track":"المسار",
        "materials" : lang === "english" ? "Materials":"المواد العلمية",
        "tasks":      lang === "english" ? "Tasks":"المهام" ,
        "withDate":   lang === "english" ? "By The Date of":"بتاريخ",
        "noTasks":    lang === "english" ? "There is No Tasks":"لا توجد مهام",
        "noMaterial": lang === "english" ? "There is No Materials":"لا توجد مواد"
    }
    function OpenCloseBox(event){
        const element = event.currentTarget.parentElement.querySelector(".materialsAndTasks")
        if (event.currentTarget.parentElement.classList.contains("close")){
            event.currentTarget.parentElement.classList.remove("close")
            event.currentTarget.parentElement.classList.add("open")
            element.style.height =element.scrollHeight +"px"

        }else{
            event.currentTarget.parentElement.classList.remove("open")
            event.currentTarget.parentElement.classList.add("close")
            element.style.height ="0px"
        }


    }
    function markTodo(event){
        const taskID= event.currentTarget.id.replace("task","")
        if (event.currentTarget.classList.contains("done")){
            event.currentTarget.classList.remove("done")

            axios.post(public_variables.backend_url+"/todo/mark_not_done",{recordID:taskID}).then((res)=>{
                console.log("res:",res)
            }).catch((err)=>{
                console.log("Error:",err)
                showMessage(err.response.data)
            })

        }else{
            event.currentTarget.classList.add("done")
            axios.post(public_variables.backend_url+"/todo/mark_done",{recordID:taskID}).then((res)=>{
                console.log("res:",res)
            }).catch((err)=>{
                console.log("Error:",err)
                showMessage(err.response.data)
            })
        }
        
    }
    // Active Track 
    const [TracksDropList,SetTrachksDropList] = useState(" - ")
    const [activeTrackID,setActiveTrackID] = useState(null)

    // Lectures List
    const [lecturesList,setLecturesList] = useState([])

    // Get Track ID's
    useEffect(()=>{
        axios.get(public_variables.backend_url+"/tracks/enrolled_tracks?userID="+localStorage.getItem("id")).then((res)=>{

            SetTrachksDropList(res.data[0]['name'])
            setActiveTrackID(res.data[0]["id"])
            const options = []
            if (res.data.length > 1){
                for(var i=0;i<  res.data.length;i++){
                    options.push(<option  onClick={(event) => setActiveTrackID(event.currentTarget.id)} id={res.data[i]['id']}>{res.data[i]['name']}</option>)
                }
                SetTrachksDropList(<DropDownInput key ={1} placeholder={res.data[0]['name']}>
                                        {options}
                                    </DropDownInput>)
            }
        }).catch((err)=>{
            console.log("Error:",err)
            showMessage(err.response.data)
        })
    },[])


    // Get The Lectures with Each one's Data
    useEffect(()=>{
        if (activeTrackID){
            axios.get(public_variables.backend_url+`/lectures/lectures?trackID=${activeTrackID}&userID=${localStorage.getItem("id")}`)
            .then((res)=>{
                const data = res.data
                const temp = []

                for (var i =0;i<data.length;i++){
                    var mat = []
                    var suggestedMat = []
                    var tasks = []

                    // Add Materials
                    for(var x = 0;x<data[i]['materials'].length;x++){
                        const elmnt = <div className={"materialBox "+ (data[i]['materials'][x]['video']?"video":"file")}>
                                        <div className="row">
                                            {data[i]['materials'][x]['video']?
                                                <FontAwesomeIcon icon="fa-solid fa-circle-play" />
                                                :
                                                (!data[i]['materials'][x]['link'] || data[i]['materials'][x]['link'] ==="")?
                                                    <FontAwesomeIcon icon="fa-solid fa-file-lines" />
                                                    :
                                                    <FontAwesomeIcon icon="fa-solid fa-link" />
                                            }
                                            {
                                            (!data[i]['materials'][x]['link'] || data[i]['materials'][x]['link'] === ""  || data[i]['materials'][x]['video'])?
                                                <span>{data[i]['materials'][x]['title']}</span>
                                                :
                                                <a target="__blank" href={data[i]['materials'][x]['link']}><span>{data[i]['materials'][x]['title']}</span></a>
                                            }
                                        </div>

                                        {
                                        data[i]['materials'][x]['video']? 
                                            <div className="videoContainer">
                                                <iframe width="560" height="315" src={data[i]['materials'][x]['link']} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen="true"></iframe>
                                            </div>
                                        :
                                        null
                                        }
                                    </div>

                        if (data[i]['materials'][x]['suggestion']){
                            suggestedMat.push(elmnt)
                        }else{
                            mat.push(elmnt)
                        }
                    }

                    // Add Tasks
                    for(var x = 0;x<data[i]['tasks'].length;x++){
                        tasks.push(
                            <div id={"task"+data[i]['tasks'][x]["id"]} className={"taskBox "+ (data[i]['tasks'][x]["done"]?"done":"")} onClick={markTodo}>
                                <div className="BoxIcon"></div>
                                <FontAwesomeIcon icon="fa-solid fa-square-check" /> 
                                <span>{data[i]['tasks'][x]["title"]}</span>
                            </div>
                        )
                    }

                    // Check Empty
                    if (mat.length === 0){
                        mat = <p className="noContent">{pageText['noMaterial']}</p>
                    }
                    if (tasks.length === 0){
                        tasks = <p className="noContent">{pageText['noTasks']}</p>
                    }

                    // Combine Each Lecture With its Materials and Tasks
                    var formatedDate = formatDateWithDaysRemaining(data[i]['date']).miniformattedDate
                    temp.push(
                        <div key={data[i]['id']} id={"Lecture"+data[i]['id']} className="LectureBox close">
                                <div className="header"  onClick={OpenCloseBox}>
                                    <div className="headerInfo">
                                        <h2>{data[i]['title']}</h2>
                                        <span className="LectureDate">{pageText['withDate']} {formatedDate}</span>
                                    </div>
                                    <div className="arrowIcon">
                                            <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
                                    </div>
                                </div>
                                
                                <div className="materialsAndTasks">
                                    <h3 className="materialsTitle">{pageText['materials']}: </h3>
                                    <div id={"material" + data[i]['id']} className="materials">
                                        {mat}
                                    </div>
                                    {suggestedMat.length?
                                    <>
                                        <h3 className="materialsTitle">الترشيحات: </h3>
                                        <div id={"material" + data[i]['id']} className="materials">
                                            {suggestedMat}
                                        </div>
                                    </>
                                    :''
                                    }
                                    
                                    <h3 className="taskTitle">{pageText['tasks']}:</h3>
                                    <div id={"tasks" + data[i]['id']} className="tasks">
                                        {tasks}
                                    </div>
                                </div>
                        </div>
                    )
                }
                setLecturesList(temp)
                
            }).catch((err)=>{
                console.log("Error:",err)
                showMessage(err.response.data)
            })
        }
    },[activeTrackID])

    return (
        <div id="LecturesPage">
            <MainHeader title={pageText['title']} comment={pageText['comment']}/>
            <div className="LecturesContainer">
                <div className="containerHeader">
                    <div className="left">
                        <h3 className="ConatinerTitle">{pageText['myLec']}</h3>
                    </div>
                    <div className="SelectDropdownContainer">
                        <span>{pageText['select']}: </span>
                        
                        {/* <DropDownInput placeholder={pageText['select']} options={["مشكاة","تكوين السبت","تكوين الاثنين"]}/> */}
                        {TracksDropList}
                    </div>
                </div>
                <div className="lectureBoxesContainer">
                    {lecturesList}
                </div>
            </div>
        </div>
    );
}

export default LecturesPage;
