
import "./styles/header.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProfileImage from "../content/profileImage.svg"
import DropDownInput from "./DropDownInput";
function MainHeader({title,comment, children}) {

    return (
        <div className="MainHeader">
            <div className="headerLeft">
                
                    <p className="pageHeader">{title}</p>
            
                    <p className="headerComment">{comment} {children}</p>
            </div>
            <div className="headerRight">
                <div className="profileImage">
                    <img src={ProfileImage}/>
                </div>
                <div className="row">
                    <p>{localStorage.getItem("name")}</p>
                    <span>{
                        localStorage.getItem("role") === "user"? "طالب":
                        localStorage.getItem("role") === "admin"?"مشرف":"مسئول"}</span>
                </div>
            </div>

        </div>
    );
}

export default MainHeader;
