function checkLoggedIn(route = '/'){
    const id = localStorage.getItem("id")
    const token = localStorage.getItem("token")
    const role = localStorage.getItem("role")
    if ( id && token && role  ){
        window.location.href = route
    }
}



export default checkLoggedIn;