import axios from "../../functions/axiosAuth"
import InputField from "../../components/Input";
import logoImage from "../../content/logo.png"
import public_variables from "../../publicVar";
import "./Signup.css"
import { useState } from "react";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";
import {validateEmail,validatePhone} from "../../functions/validate";
import { useSideMessage } from "../../components/Error/SideMessageContext";

function SignupPage() {
  // Error Message 
  const { showMessage } = useSideMessage();
  // Massage
  const [message,setMessage] = useState(null)
  const [isError,setIsError] = useState(false)

  
  // Gender Input and Token
  const [gender,setGender]  = useState(null)
  const {token} = useParams()
  const tokenData = null

  // Send Data To API
  function submitTheSignupForm(event){
    const name = document.getElementById("nameInput").querySelector("input").value
    const password = document.getElementById("passwordInput").querySelector("input").value
    const repassword = document.getElementById("repasswordInput").querySelector("input").value
    const email = document.getElementById("emailInput").querySelector("input").value
    const phone = document.getElementById("phoneInput").querySelector("input").value

    // Form Validation
    if(
      name === "" ||
      password === "" ||
      phone === "" ||
      !gender
    ){
      setIsError(true)
      setMessage("برجاء ملئ الخانات بالاسفل")
      return
    }else if(password.length <=6){
      setIsError(true)
      setMessage("كلمة السر يجب ان تكون اطول من 6 احرف")
      return
    }else if(password !== repassword){
      setIsError(true)
      setMessage("كلمتا السر غير متطابقتين")
      return
    }else if(!validateEmail(email) && email !== ''){
      setIsError(true)
      setMessage("البريد الألكتروني غير صحيح")
      return
    }else if(!validatePhone(phone)){
      setIsError(true)
      setMessage("رقم الهاتف غير صحيح")
      return
    }

    // Clear the Message
    setIsError(false)
    setMessage(null)
    const reqData = {
        token: token,
        password: password,
        email: email === ''?null:email,
        name: name,
        phone: phone,
        gender: gender
      }
    
    axios.post(public_variables.backend_url+"/auth/signup",reqData).then(res=>{
      setIsError(false)
      setMessage("يتم التسجيل ")  
      window.location.href = "/login"

    }).catch((error)=>{
      setIsError(true)
      setMessage(error.response.data)
      console.log("Error!!",error)
      showMessage(error.response.data)
    })
  }

  // Handle From With Enter Key
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default form submission behavior
      submitTheSignupForm();
    }
  };
  return (
    <div id="SignupPage">
      <Helmet>
        <title>{tokenData?tokenData['trackName']:"Mishkah"}</title>
        <link rel="icon" href={logoImage} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content={`تسجيل حساب جديد`} />
        <link rel="apple-touch-icon" href={logoImage} />
      </Helmet>
      <div className="registerSection">
        <div className="logoImageContainer">
          <img src={logoImage} className="logoImage"/>
        </div>
        <div className="registerFormContainer">
          <div className="registerform">
            <h1 className="formTitle">اهلا بك في مشكاة</h1>
            <h3>قم بتسجيل الحساب</h3>
            <span className={isError?"errorMessage":"correctMessage"}>{message}</span>
            <p className="ErrorMessage"></p>
            <InputField type={"text"} id={"nameInput"} name={"name"} placeholder={"الاسم (يفضل بالعربي)"}  className={"usernameFiled"} onKeyDown={handleKeyDown} required={true} />
            <InputField type={"number"} id={"phoneInput"} name={"phone"} placeholder={"رقم الهاتف"}  className={"usernameFiled"} onKeyDown={handleKeyDown} required={true}/>
            <InputField type={"password"} id={"passwordInput"} name={"password"} placeholder={"كلمة السر"}  className={"passwordFiled"} onKeyDown={handleKeyDown} required={true}/>
            <InputField type={"password"} id={"repasswordInput"} name={"repassword"} placeholder={"اعد كتابةكلمة السر"}  className={"passwordFiled"} onKeyDown={handleKeyDown} required={true}/>
            <InputField type={"email"} id={"emailInput"} name={"email"} placeholder={"البريد الألكتروني"}  className={"usernameFiled"} onKeyDown={handleKeyDown}/>

            <div  id="genderInput" class="form__group">
              <p className="grayComment">الجنس: </p>
              <div class="form__radio_group">
                <input id="Male" type="radio" class="form__radio-input" name="gender" onClick={()=>{setGender('male')}}/>
                <label for="Male" class="form__radio-label">
                  <span class="form__radio-button"></span>
                  <span class="form__radio-label-text">ذكر</span>

                  </label>
              </div>

              <div class="form__radio_group">
                <input id="Female" type="radio" class="form__radio-input" name="gender" onClick={()=>{setGender("female")}} />
                <label for="Female" class="form__radio-label">
                  <span class="form__radio-button"></span>
                  <span class="form__radio-label-text">أنثي</span>
                </label>
              </div>
            </div>
            <button onClick={submitTheSignupForm}>انشاء حساب</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignupPage;