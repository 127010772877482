
import "./DownPage.css"
import ConnectionLostImage from '../../content/ConnectonLost.png'
function DownPage() {
    
    return (
        <div id="ServerDownPage" className="hide">
            <img src={ConnectionLostImage}/>
            <h2>فقدنا الأتصال مؤقتا, استني شويه و جرب تاني</h2>
        </div>
    );
}

export default DownPage;
