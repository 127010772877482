import "./AdminTrackAssign.css"

import axios from "../../functions/axiosAuth"
import logoImage from "../../content/logo.png"
import public_variables from "../../publicVar";

import { useState,useEffect } from "react";
import { useParams } from "react-router";
import checkLoggedIn from "../../functions/checkLogin";
import { useSideMessage } from "../../components/Error/SideMessageContext";
import TrackBox from "../../components/TrackBox";
import { Helmet } from 'react-helmet';

function AdminTrackAssignPage() {


  // Error Message 
  const { showMessage } = useSideMessage();

  // Check if Logged in
  const id = localStorage.getItem("id")
  const role = localStorage.getItem("role")

  // Get Token From Link
  const {token} = useParams()

  // Message
  const [message,setMessage] = useState(null)
  const [isError,setIsError] = useState(false)


  // Redirect to the Registration Link With the Token
  function AssignTracks(event){
    const reqData = {
      token:token,
      adminID:localStorage.getItem("id")
    }
    axios.post(public_variables.backend_url+"/admin-tracks/assign_track",reqData).then(res=>{
      window.location.href = '/'
      
    }).catch((error)=>{
      setMessage(error.response.data)
      setIsError(true)
      console.log("Error!!",error)
      showMessage(error.response.data)
    })
  }


  // Redirect to Home Page
  function GoToHomePage(event){
    window.location.href = '/'
  }
  const [offeredTrack,setOfferedTrack] = useState([])
  useEffect(()=>{
    axios.get(public_variables.backend_url+`/admin-tracks/track_info?token=${token}`).then(res=>{
      setOfferedTrack(res.data)
      
    }).catch((error)=>{
      setMessage(error.response.data)
      setIsError(true)
      console.log("Error!!",error)
      showMessage(error.response.data)
    })
  },[])

  return (
    <div id="AdminEnrollPage">
      {/* Adding Helmet to Add Link Meta Data */}
      <Helmet>
        <title>Mishkah System</title>
        <link rel="icon" href={logoImage} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content={`التسجيل في مسار`} />
        <link rel="apple-touch-icon" href={logoImage} />
      </Helmet>
      <div className="registerSection">
        <div className="logoImageContainer" onClick={GoToHomePage}>
          <img src={logoImage} className="logoImage"/>
        </div>
        <div className="offerdTrackContainer">
        <span className={isError?"errorMessage":"correctMessage"}>{message}</span>
        
        {offeredTrack.length?<span className={"grayMessage"}>المسارات المطروحة خلال الرابط: </span>:""}
        <div className="tracksListDiv">
          {offeredTrack.map(localTrackInfo=>{
            return <TrackBox
            id={localTrackInfo["id"]}
            title={localTrackInfo["name"]} 
            description={localTrackInfo["description"]}
            imgLink={localTrackInfo["imageLink"]}
            lectureCount={localTrackInfo['lecturesCount']}
          />
          })}

        </div>
          {isError?null: // Check if the Link is Valid or Not
            (role === "admin") ?
              <div className="row">
                <button className="RegisterButton" onClick={AssignTracks}>تسجيل في المسارات</button>
              </div>
            :
              <span className="errorMessage">لا يمكنك التسجيل بهذا الحساب</span>
            
          }

        </div>
        
      </div>
    </div>
  );
}

export default AdminTrackAssignPage;
