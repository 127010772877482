import React from 'react';
import "./styles/OverviewBox.css"

const OverviewBox = ({ title, className, Borderless, children }) => {
    return (
        <div className={"OverViewBoxComponent "+(Borderless?"Borderless ":"")+ className}>
            <div className="BoxTitle">
                <p>{title}</p>
            </div>
            {children}
        </div>
    );
};

export default OverviewBox;
