import axios from "../../functions/axiosAuth"
import logoImage from "../../content/logo.png"
import public_variables from "../../publicVar";
import "./TrackInfo.css"
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import TrackBox from "../../components/TrackBox";
import { Helmet } from 'react-helmet';
import { useSideMessage } from "../../components/Error/SideMessageContext";

function TrackInfoPage() {
  // Error Message 
  const { showMessage } = useSideMessage();

  // Check if Logged in
  const id = localStorage.getItem("id")
  const role = localStorage.getItem("role")

  // Get Token From Link
  const {token} = useParams()

  // Message
  const [message,setMessage] = useState(null)
  const [isError,setIsError] = useState(false)
  
  // Send Data To API
  function registerInTrack(event){
    const trackID = document.querySelector("#RegisterPage .TrackBox").id
    const reqData= {
      trackID:trackID,
      studentID:id
    }
    axios.post(public_variables.backend_url+"/tracks/track_register",reqData).then(res=>{
      window.location.href = "/"

    }).catch((error)=>{

      console.log("Error!!",error)
      showMessage(error.response.data)
    })
  }

  // Redirect to the Registration Link With the Token
  function GoToSignup(event){
    // Redirect to the Registration Link With the Token
    window.location.href = public_variables.user_signup+token
  }

  // Go To Login First
  function GoToLogin(event){
    localStorage.setItem("redirected",`/trackinfo/${token}`)
    window.location.href = "/login"
  }

  // Redirect to Home Page
  function GoToHomePage(event){
    window.location.href = '/'
  }
  const [offeredTrack,setOfferedTrack] = useState(null)
  const [offeredTrackName,setOfferedTrackName] = useState(null)
  const [offeredTrackImage,setOfferedTrackImage] = useState(null)
  useEffect(()=>{
    axios.get(public_variables.backend_url+`/tracks/track_info?token=${token}`).then(res=>{
      console.log(res.data)
      setOfferedTrackName(res.data['name'])
      setOfferedTrackImage(res.data['imageLink'])
      setOfferedTrack(
        <TrackBox
          id={res.data["id"]}
          title={res.data["name"]} 
          description={res.data["description"]}
          imgLink={res.data["imageLink"]}
          lectureCount={res.data['lecturesCount']}
        />
      )
    }).catch((error)=>{
      setMessage(error.response.data)
      setIsError(true)
      console.log("Error!!",error)
      showMessage(error.response.data)
    })
  },[])

  return (
    <div id="RegisterPage">
      {/* Adding Helmet to Add Link Meta Data */}
      <Helmet>
        <title>Mishkah System</title>
        <link rel="icon" href={offeredTrackImage} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content={`التسجيل في مسار`} />
        <link rel="apple-touch-icon" href={offeredTrackImage} />
      </Helmet>
      <div className="registerSection">
        <div className="logoImageContainer" onClick={GoToHomePage}>
          <img src={logoImage} className="logoImage"/>
        </div>
        <div className="offerdTrackContainer">
        <span className={isError?"errorMessage":"correctMessage"}>{message}</span>
          {offeredTrack}
          {isError?null: // Check if the Link is Valid or Not
            !(id && id !== "" && role !== "student") ?
              <div className="row">
                <button className="RegisterButton" onClick={GoToSignup}>تسجيل حساب جديد</button>
                <button className="RegisterButton" onClick={GoToLogin}>املك حساب بالفعل</button>
              </div>
            :
              (role === "admin" || role === "super-admin")?
                <span className="errorMessage">لا يمكنك التسجيل بحساب المسئول</span>
              :
                <button className="RegisterButton" onClick={registerInTrack}>تسجيل في المسار</button>
            
          }

        </div>
        
      </div>
    </div>
  );
}

export default TrackInfoPage;
