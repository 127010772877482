import React from 'react';
import { useSideMessage } from './SideMessageContext'; // Adjust the path as needed
import './SideMessage.css'; // Import the CSS file

const SideMessage = () => {
    const { messages } = useSideMessage();

    return (
        <div className="message-container">
            {messages.map((msg, index) => (
                <div
                    key={msg.id}
                    className={`message ${msg.type} show`}
                    style={{ bottom: `${21 + index * 60}px` }}
                >
                    {msg.message}
                </div>
            ))}
        </div>
    );
};

export default SideMessage;
