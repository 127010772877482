import React, { useState, useEffect } from 'react';

const Counter = ({ value ,speed = 500}) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const duration = speed; // Duration of the animation in milliseconds
    const steps = 50; // Number of steps for the animation
    const stepValue = Math.ceil(value / steps);
    let currentStep = 0;

    const interval = setInterval(() => {
      currentStep++;
      const newValue = currentStep * stepValue;
      setCount(newValue);

      if (currentStep === steps || newValue >= value) {
        clearInterval(interval);
        setCount(value);
      }
    }, duration / steps);

    return () => clearInterval(interval);
  }, [value]);

  return (
    <div>{count}</div>
  );
};

export default Counter;
