import "./styles/DropDownInput.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, cloneElement, Children, useEffect } from "react";

function DropDownInput({ placeholder,id, className, subTitle, missing,innerSearch=false ,saveValue=false, disabled=false, children }) {
    const [selected, setSelected] = useState(null);
    const [SelectedID,setSelectedID] = useState(null)
    function DropMenu(event) {
        const dropdownParent = event.currentTarget.parentElement;
        const element = dropdownParent.querySelector(".DropDownOptions");
    
        if (dropdownParent.classList.contains("close")) {
            // Open the dropdown
            dropdownParent.classList.remove("close");
            dropdownParent.classList.add("open");
            element.style.height = element.scrollHeight + "px";
    
            // Add a listener to close the dropdown when clicking outside
            document.addEventListener("click", handleClickOutside);
        } else {
            // Close the dropdown if clicked again
            closeDropdown();
        }
    
        function closeDropdown() {
            dropdownParent.classList.remove("open");
            dropdownParent.classList.add("close");
            element.style.height = "0px";
            document.removeEventListener("click", handleClickOutside);
        }
    
        function handleClickOutside(e) {
            if (!dropdownParent.contains(e.target)) {
                closeDropdown();
            }
        }
    
        // Stop the click event from propagating to the document
        event.stopPropagation();
    }

    function selectOption(event) {
        if(subTitle){
            setSelected(event.currentTarget.querySelector("option").innerHTML);
            setSelectedID(event.currentTarget.querySelector(".ID").innerHTML)

            // Save Value to Local Storage
            if(saveValue){
                localStorage.setItem(`newInfo_${id}Value`,event.currentTarget.querySelector("option").innerHTML)
                localStorage.setItem(`newInfo_${id}ID`,event.currentTarget.querySelector(".ID").innerHTML)
            }
        }else{
            setSelected(event.currentTarget.innerHTML);

            // Save Value to Local Storage
            if(saveValue){
                localStorage.setItem(`newInfo_${id}Value`,event.currentTarget.innerHTML)
            }
        }
        event.currentTarget.closest(".DropDownInput").querySelector(".InputHeader").click();

    }

    function FilterChildren(event){
        const SearchQuery = event.currentTarget.value.toLowerCase()
        const options = event.currentTarget.closest(".DropDownOptions")
        const optionList = options.querySelectorAll("option")
        for (const op of optionList){
            var opVal = op.value.toLowerCase()
            if(opVal.includes(SearchQuery)){
                // Check Sub For Div
                if (subTitle){
                    op.closest('.optionDiv').classList.remove('hide')
                }else{
                    op.classList.remove('hide')
                }

            }else{

                // Check Sub For Div
                if (subTitle){
                    op.closest('.optionDiv').classList.add('hide')
                }else{
                    op.classList.add('hide')
                }
            }
        }

    }
    const childrenWithClickHandler = Children.map(children, child => {
        // Check if the child is an element with the class 'option' or is an 'option' tag
        if ( child.type === 'option' || ( child.props && child.props.className === 'optionDiv' )) {
            const originalOnClick = child.props.onClick;
            return cloneElement(child, {
                onClick: (event) => {
                    selectOption(event);
                    if (originalOnClick) {
                        originalOnClick(event);
                    }
                }
            });
        }
    
        // If not, return the child as is
        return child;
    });
    
    // Load if there is Saved Data
    useEffect(()=>{
        if (localStorage.getItem(`newInfo_${id}Value`)){
            setSelected(localStorage.getItem(`newInfo_${id}Value`))
        }
        if (localStorage.getItem(`newInfo_${id}ID`)){
            setSelectedID(localStorage.getItem(`newInfo_${id}ID`))

        }
    },[])

    return (
        <div  id={id} className={(className?className:'')+" DropDownInput close"+(disabled?" disabled":"")}>
            <div className="InputHeader" onClick={DropMenu}>
                <div className={`selected ${'ID'+SelectedID}`}>
                    {selected ? selected : <span className="placeholder">{placeholder}</span>} 
                </div>
                &nbsp;&nbsp;&nbsp;
                <div className="arrowIcon">
                        <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
                </div>
                {/* <span  className='required'>This Field is Required</span> */}
            </div>
            {missing && (!selected) ?<span  className='required'>* This Field is Required</span>:null}

            <div className="DropDownOptions">
                {innerSearch?<input type='text' placeholder={`Search ${placeholder}`} className='innerSearchInput' onChange={FilterChildren}/>:''}

                {childrenWithClickHandler}
            </div>
        </div>
    );
}

export default DropDownInput;
