import axios from "../../functions/axiosAuth"
import InputField from "../../components/Input";
import background from "../../content/Login.png"
import logoImage from "../../content/logo.png"
import public_variables from "../../publicVar";

import "./Register.css"
import { useEffect, useState } from "react";
import DropDownInput from "../../components/DropDownInput";
import { useParams } from "react-router";
import {validateEmail,validatePhone} from "../../functions/validate";
import checkLoggedIn from "../../functions/checkLogin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSideMessage } from "../../components/Error/SideMessageContext";
// import crypto from 'crypto-browserify'

function RegisterPage() {
  // // Check if Already Logged-in, Redirect to home ./
  // checkLoggedIn()
  // Error Message 
  const { showMessage } = useSideMessage();

  // Massage
  const [message,setMessage] = useState(null)
  const [isError,setIsError] = useState(false)
  
  // Gender Input and Token
  const [gender,setGender]  = useState(null)
  const [hasAccount,sethasAccount]  = useState(false)
  const [validLink,setValidLink] = useState(true)
  const {token} = useParams()
  const [trackID,setTrackID] = useState('')
  const [trackName,setTrackName] = useState('')

  useEffect(()=>{
    var trackID = null;
    // try{
    //   const decoded = AECdecrypt(token);
    //   console.log(decoded)
    //   trackID = JSON.parse(decoded)['track']
    
    // }catch(err){
    //   console.log(err)
    //   return 
    // }

    axios.get(public_variables.backend_url+`/tracks/track_info?trackID=${trackID}&token=${token}`).then(res=>{
      console.log(res.data)
      const data = res.data
      const newDate = new Date()
      const trackDate = new Date(data['EnrollmentDeadline'])
      if(trackDate < newDate){
        setValidLink(false)
        return 
      }
      setTrackName(res.data['name'])
      setTrackID(res.data['id'])

    }).catch((error)=>{
      setValidLink(false)
      console.log("Error!!",error)
      showMessage(error.response.data)
    })
  },[])

  // Send Data To API
  async function submitTheRegisterForm(event){
    const name = document.getElementById("nameInput").querySelector("input").value
    const phone = document.getElementById("phoneInput").querySelector("input").value
    const email = document.getElementById("emailInput").querySelector("input").value

    // Form Validation
    if(
      name === "" ||
      phone === "" ||
      !gender
    ){
      setIsError(true)
      setMessage("برجاء ملئ الخانات بالاسفل")
      return
    }else if(!validatePhone(phone)){
      setIsError(true)
      setMessage("رقم الهاتف غير صحيح")
      return
    }else if(email !== '' && !validateEmail(email)){
      setIsError(true)
      setMessage("البريد الألكتروني غير صحيح")
      return
    }

    const phone_or_email = {
      email:email,
      phone:phone
    }
    // check if has Account
    try{
      if(hasAccount){
        const check = await axios.post(public_variables.backend_url+"/tracks/check_exist",phone_or_email)
        if(!check.data){
          setIsError(true)
          setMessage("لا يوجد حساب برقم الهاتف او الايميل المسجلين")
          return
        }
      }
    }catch(err){
      console.log("Error!!",err)
      showMessage(err.response.data)
    }
    
    
    // Clear the Message
    setIsError(false)
    setMessage(null)
    const reqData = {
        full_name: name,
        trackID: trackID,
        phone: phone,
        email: email,
        gender: gender ,
        has_account:hasAccount,
      }
    
    axios.post(public_variables.backend_url+"/tracks/registration",reqData).then(res=>{
      setIsError(false)
      setMessage("يتم التسجيل ")  
      document.querySelector("#RegisterPage .registerFormContainer").classList.add("hide")
      document.querySelector("#RegisterPage .welcomeSection").classList.remove("hide")

    }).catch((error)=>{
      setIsError(true)
      setMessage(error.response.data)
      console.log("Error!!",error)
      showMessage(error.response.data)
    })
  }


  // Handle From With Enter Key
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default form submission behavior
      submitTheRegisterForm();
    }
  };

  return (
    <div id="RegisterPage">
      {/* <img src={background} className="background" /> */}
      <div className="registerSection">
        <div className="logoImageContainer">
          <img src={logoImage} className="logoImage"/>
        </div>
        <div className={"registerFormContainer"+(validLink?"":' hide')}>
          <div className="registerform">
            <h1 className="formTitle">اهلا بك في مشكاة</h1>
            <h3>قم بالتسجيل في مسار {trackName}</h3>
            <span className={isError?"errorMessage":"correctMessage"}>{message}</span>
            <p className="ErrorMessage"></p>
            <InputField type={"text"} id={"nameInput"} name={"name"} placeholder={"الاسم (يفضل بالعربي)"}  className={"usernameFiled"} onKeyDown={handleKeyDown} required={true}/>
            <InputField type={"number"} id={"phoneInput"} name={"phone"} placeholder={"رقم الهاتف"}  className={"usernameFiled"} onKeyDown={handleKeyDown} required={true} />
            <InputField type={"email"} id={"emailInput"} name={"email"} placeholder={"البريد الألكتروني"}  className={"usernameFiled"} onKeyDown={handleKeyDown} />
            
            <DropDownInput id={"genderInput"} placeholder={"الجنس"} required={true}>
              <option onClick={(event)=>{setGender("male")}}>ذكر</option>
              <option onClick={(event)=>{setGender("female")}}>أنثي</option>
            </DropDownInput>
            <div className="checkBoxInput">
                <span> تملك حساب؟</span>
                <div className={"checkBox"+(hasAccount?" checked":"")}  onClick={()=>{sethasAccount(!hasAccount)}}>
                  {hasAccount?<FontAwesomeIcon icon="fa-solid fa-check" />:""}
                </div>
            </div>
            <button onClick={submitTheRegisterForm}>تسليم الأستمارة</button>
          </div>
        </div>
        <div className="welcomeSection hide">
          <div className="messageContainer">
              <span>تم تسجيل الاستمارة بنجاح</span>
          </div>  
        </div>
        <div className={"welcomeSection"+(validLink?" hide":'')}>
          <div className="messageContainer">
              <span>الرابط لم يعد صالحا, اذا كان هناك مشكلة برجاء التواصل مع المشرف</span>
          </div>  
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
