
import "./StudentsPage.css"
import MainHeader from "../../components/header";
import Counter from "../../components/Counter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropDownInput from "../../components/DropDownInput";
import { useEffect, useState } from "react";
import axios from "axios";
import public_variables from "../../publicVar";
import { useSideMessage } from "../../components/Error/SideMessageContext";
function UsersPage() {
    // Error Message 
    const { showMessage } = useSideMessage();

    function activateStat(event){

        if ( event.currentTarget.classList.contains("active")){
            event.currentTarget.classList.remove("active")
        }else{
            const activeChoise = event.currentTarget.parentElement.querySelector(".active")

            if(activeChoise){
                activeChoise.classList.remove("active")
            }
            event.currentTarget.classList.add("active")
        }
    }

    // Active Track 
    const [TracksDropList,SetTrachksDropList] = useState(" - ")
    const [activeTrackID,setActiveTrackID] = useState(null)


    // Attendance Sheet and Names
    const [studentsList,setStudentsList] = useState([])
    const [origianlStudentsList,setOriginalStudentsList] = useState([])
    const [SearchQuery,setSearchQuery] = useState(null)
    const [message,setMessage] = useState(null)
    const [isError,setIsError] = useState(false)

    // Choose Track Actions
    function ChooseTrack(event){
        setActiveTrackID(event.currentTarget.id);
    }

    // Block an Account
    function BlockAccount(event){
        const mainCard = event.currentTarget.closest(".studentBox")
        const userID = mainCard.id.replace("student","")
        const reqData = {
            userID:userID
        }
        axios.post(public_variables.backend_url+"/super-admin/student_block",reqData).then((res)=>{
            mainCard.querySelector(".unblockButton").classList.remove("hide")
            mainCard.querySelector(".blockButton").classList.add("hide")
        }).catch(err=>{
            console.log("Error!!",err)
            showMessage(err.response.data)
        })
    }

    // un-Block an Account
    function unBlockAccount(event){
        const mainCard = event.currentTarget.closest(".studentBox")
        const userID = mainCard.id.replace("student","")
        const reqData = {
            userID:userID
        }
        axios.delete(public_variables.backend_url+"/super-admin/student_block",{data:reqData}).then((res)=>{
            mainCard.querySelector(".unblockButton").classList.add("hide")
            mainCard.querySelector(".blockButton").classList.remove("hide")
        }).catch(err=>{
            console.log("Error!!",err)
            showMessage(err.response.data)
        })
    }

    // Select Delete  to Show the Delete Button
    function ShowDeleteButton(event){
        event.currentTarget.parentElement.querySelector(".sureButton").classList.remove("hide")
    }
    // Ask Are You Sure Before Un-Enroll
    function askBeforeUnenroll(event){
        const askButton = event.currentTarget
        const currentTrack = event.currentTarget.closest(".trackSpan")
        const trackName = currentTrack.querySelector(".trackName")
        const deleteButton = currentTrack.querySelector(".deleteButton")

        askButton.classList.add("hide")
        trackName.classList.add("hide")
        deleteButton.classList.remove("hide")
    }

    // Delete an Account
    function unenrollStudent(event){
        const studentID = event.currentTarget.closest(".studentBox").id.replace("student","")
        const currentTrack = event.currentTarget.closest(".trackSpan")
        const trackID = currentTrack.id.replace("track","")

        const reqData = {
            studentID:studentID,
            trackID:trackID
        }
        axios.delete(public_variables.backend_url+"/super-admin/student_enrollment",{data:reqData}).then((res)=>{
            currentTrack.classList.add("hide")
        }).catch(err=>{
            console.log("Error!!",err)
            showMessage(err.response.data)
        })
    }

    // Delete an Account
    function DeleteAccount(event){
        const userID = event.currentTarget.closest(".studentBox").id.replace("student","")
        const reqData = {
            userID:userID
        }
        axios.delete(public_variables.backend_url+"/super-admin/student_account",{data:reqData}).then((res)=>{
            window.location.reload()
        }).catch(err=>{
            console.log("Error!!",err)
        })
    }

    // Get Track ID's
    useEffect(()=>{
        axios.get(public_variables.backend_url+"/admin-tracks/assigned_tracks?adminID="+localStorage.getItem("id")).then((res)=>{
            setActiveTrackID(res.data[0]['id'])
            const options = []
            for(var i=0;i<  res.data.length;i++){
                options.push(<option  onClick={ChooseTrack} id={res.data[i]['id']}>{res.data[i]['name']}</option>)
            }
            SetTrachksDropList(<DropDownInput key ={1} placeholder={res.data[0]['name']}>
                                    {options}
                                </DropDownInput>)

        }).catch((err)=>{
            console.log("Error:",err)
            showMessage(err.response.data)
        })
    },[])

    // Get Student From the Selected Track
    useEffect(()=>{
        if(!activeTrackID){
            return 
        }
        axios.get(public_variables.backend_url+"/super-admin/student_list?trackID="+activeTrackID).then((res)=>{

                setStudentsList(res.data)
                setOriginalStudentsList(res.data)
                document.querySelector("#StudentSearchInput").value = ''
                setSearchQuery(null)
        }).catch((err)=>{
            console.log("Error:",err)
            showMessage(err.response.data)
        })
    },[activeTrackID])
    function filterStudents(event){
        const query = event.currentTarget.value.toLowerCase()
        setStudentsList(origianlStudentsList.filter(student=>{return student.name.toLowerCase().includes(query)}))
        setSearchQuery(query)
    }
    return (
        <div id="UsersPage">
            <MainHeader title={"الطلاب"} comment={""}/>
            <div className="PageContainer">
                <div className="containerHeader">
                    <div className="searchInputContainer">
                        <input type="text" placeholder={"ابحث عن الطلاب بالأسم "} id={"StudentSearchInput"} onChange={filterStudents}/>
                    </div>
                    <div className="selectSection">
                        {TracksDropList}
                    </div>
                    
                </div>
                <div className="studentsConatiner">
                    {studentsList.length?
                    studentsList.map(student=>{
                        return <div className="studentBox" id={"student"+student.id}>
                                    <div className="column">
                                        <div className="nameEmail">
                                        <h3 className="studentName">{student.name} <span className={student.sex?"female":"male"}>{student.sex?"أنثي":"ذكر"}</span></h3>
                                        <span className={"studentEmail"+(student.verified? " verified":"")} title={student.verified? "موثق":"غير موثق"}>{student.email} <FontAwesomeIcon icon="fa-solid fa-circle-check" /></span>
                                        </div>
                                        <div className="enrolledTracks">
                                            مشترك ايضا في: 
                                            &ensp;
                                            {student.tracks.map(track=><div className="trackSpan" id={"track"+track.id}><span className="trackName">{track.name}</span> <button onClick={askBeforeUnenroll} className="unenrollButton" title="ازل الطالب من المسار">X</button><span className="deleteButton hide" onClick={unenrollStudent}>متاكد؟</span></div>)}
                                        </div>
                                    </div>
                                    <div className="column actionButtons">
                                        <div>
                                            <p className={"actionButton blockButton" + (student['active'] ? "": " hide")} onClick={BlockAccount}>وقف الحساب</p>
                                            <p className={"actionButton unblockButton"+ (student['active'] ? " hide":'')} onClick={unBlockAccount}>ازاله وقف الحساب</p>
                                            <p className="actionButton" onClick={ShowDeleteButton}>حذف الحساب</p>
                                            <p className="actionButton sureButton hide" onClick={DeleteAccount}>هل انت متأكد</p>
                                        </div>
                                    </div>
                                </div>
                    }):<p>لا يوجد طلبة في هذا المسار <span>{SearchQuery?`بأسم: ${SearchQuery}`:''}</span></p>

                }
                </div>
                <span className="correctMessage">{message}</span>
            </div>
        </div>
    );
}

export default UsersPage;
