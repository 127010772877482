import "./styles/TrackBox.css"
import "./styles/SuperTrackBox.css"
import axios from "axios"
import public_variables from "../publicVar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import defualtImage from '../content/defualt.png'
import { useSideMessage } from "./Error/SideMessageContext"
import InputField from "./Input"
import DropDownInput from "./DropDownInput"

function SuperTrackBox({id,key,title,className,enrollmentToken,description,imgLink,lectureCount,studentsCount,passed, WeeklyDay,deadline, dayTime}) {
    // Error Message 
    const { showMessage } = useSideMessage();
    
    // Close Enrollemnt
    function closeEnrollment(event){
        const trackID = event.currentTarget.closest(".SuperTrackBox").id.replace("track",'')
        const reqData ={
            trackID:trackID,
            currentDate:new Date()
        }
        axios.post(public_variables.backend_url+"/super-admin/close_enrollment",reqData).then((res)=>{
            console.log(res.data)
            window.location.reload()
        }).catch((err)=>{
            console.log("Error!!",err)
            showMessage(err.response.data)
        })
    }

    // Copy the Link to Clipboard
    function copyToClipboard (event){
        const text = event.currentTarget.parentElement.querySelector(".link").value
        const copiedSpan = event.currentTarget.parentElement.querySelector(".copiedText")
        const copiedSVG = event.currentTarget.parentElement.querySelector(".copyIcon")
        console.log(copiedSVG)
        navigator.clipboard.writeText(text).then(() => {
            console.log(text)
            copiedSpan.classList.remove("hide")
            copiedSVG.classList.add("hide")
            setTimeout(() => {
            copiedSpan.classList.add("hide")
            copiedSVG.classList.remove("hide")
        }, 5000);
        });
    };

    // Redirect to Form Responses
    function GoToFormResponses(event){
        const trackID = event.currentTarget.closest(".SuperTrackBox").id.replace("track",'')
        localStorage.setItem("info_selectedTrackName",title)
        window.location.href = `./formresponses/${trackID}`
    }

    // Submit Track Edit Form
    function SubmitTrackEdit(event){
        const trackName = event.currentTarget.closest(".SuperTrackBox").querySelector("#TrackEditNameInput").querySelector("input").value
        const trackDescription = event.currentTarget.closest(".SuperTrackBox").querySelector("#TrackEditDescriptionInput").querySelector("input").value
        const trackLecCount = event.currentTarget.closest(".SuperTrackBox").querySelector("#TrackEditLecturesInput").querySelector("input").value
        const trackDeadline = event.currentTarget.closest(".SuperTrackBox").querySelector("#trackEditDeadlineInput").querySelector("input").value
        const trackCoverImage = event.currentTarget.closest(".SuperTrackBox").querySelector("#TrackEditImageLinkInput").querySelector("input").value
        var lectureTime = event.currentTarget.closest(".SuperTrackBox").querySelector("#trackEditLectureTime input").value

        const Days = {
            "السبت": "Saturday",
            "الأحد": "Sunday",
            "الاثنين": "Monday",
            "الثلاثاء": "Tuesday",
            "الأربعاء": "Wednesday",
            "الخميس": "Thursday",
            "الجمعة": "Friday"
        };
        const trackDayInput =  event.currentTarget.closest(".SuperTrackBox").querySelector("#trackEditDayInput").querySelector(".selected").innerHTML
        const trackDay = Days[trackDayInput]
        // Check Empty 
        if (
            trackName === "" ||
            trackDescription === "" ||
            trackLecCount === "" ||
            trackDay === undefined ||
            trackDeadline === "" ||
            !lectureTime ||
            trackCoverImage === "" 
        ){     
            console.log(trackName)
            showMessage("يجب ملئ الخانات بالاسفل")
            return;
        }

        // Fix Time Date
        if(Number(lectureTime.split(":")[0])>12){
            lectureTime =`${Number(lectureTime.split(":")[0])-12}:${lectureTime.split(":")[1]}PM`
        }else{
            lectureTime =`${Number(lectureTime.split(":")[0])}:${lectureTime.split(":")[1]}AM`
        }

        // Send to API
        const reqData = {
            trackID:id,
            name: trackName,
            lecturesCount: trackLecCount,
            description:trackDescription,
            imageLink:trackCoverImage,
            lectureDay: trackDay,
            EnrollmentDeadline: trackDeadline,
            lectureTime:lectureTime,
            currentDate:new Date()
            }
        axios.post(public_variables.backend_url+"/super-admin/track_edit",reqData).then((res)=>{
            console.log(res.data)
            showMessage("تم تعديل المسار",'success')
            window.location.reload()
        }).catch((err)=>{
            console.log("Error!!",err)
            showMessage(err.response.data)
        })
    
    }

    // Open Close Form
    function openForm(event){
        event.currentTarget.closest(".SuperTrackBox")
        .querySelector(".InfoEditForm .backgroundBlock")
        .style.display = "block"
        event.currentTarget.closest(".SuperTrackBox")
        .querySelector(".InfoEditForm")
        .style.zIndex = "999"
        event.currentTarget.closest(".SuperTrackBox")
        .querySelector(".InfoEditForm .WarningBox").classList.add("shown")

        // Set Defualt Date
        event.currentTarget.closest(".SuperTrackBox")
        .querySelector("#trackEditDeadlineInput input").valueAsDate = new Date(deadline)

        // Set Defualt Day Time
        const [time, period] = dayTime.split(/([APM]+)/);
        let [hours, minutes] = time.split(':').map(Number);

        if (period === 'PM' && hours < 12) {
            hours += 12;
        } else if (period === 'AM' && hours === 12) {
            hours = 0;
        }
        event.currentTarget.closest(".SuperTrackBox")
        .querySelector("#trackEditLectureTime input").value = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
        

        // Set Defualt Day
        const Days ={
            "Saturday": "السبت",
            "Sunday": "الأحد",
            "Monday": "الاثنين",
            "Tuesday": "الثلاثاء",
            "Wednesday": "الأربعاء",
            "Thursday": "الخميس",
            "Friday": "الجمعة"
        };
        
        const options = event.currentTarget.closest(".SuperTrackBox")
        .querySelectorAll("#trackEditDayInput option")
        for (var op of options){
            if (op.innerHTML === Days[WeeklyDay]){
                op.click()
            }
        }
        console.log("hello")
        console.log(event.currentTarget.closest(".SuperTrackBox")
        .querySelector("#trackEditLectureTime input"))

    }

    function closeForm(event){
        event.currentTarget.closest(".SuperTrackBox")
        .querySelector(".InfoEditForm .backgroundBlock")
        .style.display = "none"
        event.currentTarget.closest(".SuperTrackBox")
        .querySelector(".InfoEditForm .WarningBox").classList.remove("shown")
        event.currentTarget.closest(".SuperTrackBox")
        .querySelector(".InfoEditForm")
        .style.zIndex = "-1"
    }

    // Archive Track
    function ArchiveTrack(event){
        axios.post(public_variables.backend_url+"/super-admin/archive_track",{trackID:id,currentDate:new Date()}).then((res)=>{
            showMessage("تم ارشفة المسار",'success')
            window.location.reload()
        }).catch((err)=>{
            console.log("Error!!",err)
            showMessage(err.response.data)
        })
    }

    // Open Close Warning
    function openArchiveWarning(event){
        event.currentTarget.closest(".SuperTrackBox")
        .querySelector(".ArchiveWarningContainer .backgroundBlock")
        .style.display = "block"
        event.currentTarget.closest(".SuperTrackBox")
        .querySelector(".ArchiveWarningContainer")
        .style.zIndex = "999"
        event.currentTarget.closest(".SuperTrackBox")
        .querySelector(".ArchiveWarningContainer .WarningBox").classList.add("shown")
    }
    function closeArchiveWarning(event){
        event.currentTarget.closest(".SuperTrackBox")
        .querySelector(".ArchiveWarningContainer .backgroundBlock")
        .style.display = "none"
        event.currentTarget.closest(".SuperTrackBox")
        .querySelector(".ArchiveWarningContainer .WarningBox").classList.remove("shown")
        event.currentTarget.closest(".SuperTrackBox")
        .querySelector(".ArchiveWarningContainer")
        .style.zIndex = "-1"
    }

    // Delete Track
    function DeleteTrack(event){
        axios.delete(public_variables.backend_url+"/super-admin/track",{data:{trackID:id,currentDate:new Date()}}).then((res)=>{
            console.log(res.data)
            showMessage("تم حذف المسار",'success')
            window.location.reload()
        }).catch((err)=>{
            console.log("Error!!",err)
            showMessage(err.response.data)
        })
    }

    // Open Close Warning
    function openDeleteWarning(event){
        event.currentTarget.closest(".SuperTrackBox")
        .querySelector(".DeleteWarningContainer .backgroundBlock")
        .style.display = "block"
        event.currentTarget.closest(".SuperTrackBox")
        .querySelector(".DeleteWarningContainer")
        .style.zIndex = "999"
        event.currentTarget.closest(".SuperTrackBox")
        .querySelector(".DeleteWarningContainer .WarningBox").classList.add("shown")
    }

    function closeDeleteWarning(event){
        event.currentTarget.closest(".SuperTrackBox")
        .querySelector(".DeleteWarningContainer .backgroundBlock")
        .style.display = "none"
        event.currentTarget.closest(".SuperTrackBox")
        .querySelector(".DeleteWarningContainer .WarningBox").classList.remove("shown")
        event.currentTarget.closest(".SuperTrackBox")
        .querySelector(".DeleteWarningContainer")
        .style.zIndex = "-1"
    }

    return (
        <div className={"SuperTrackBox "+(className?className:"")} id={id} key={key}>
            <div className="WarningContainer InfoEditForm" key={`EditFormTrack${id}`}>
                    <div className="WarningContainer">
                        <div className="backgroundBlock" onClick={closeForm}></div>
                        <div className="WarningBox">
                            <h2>تعديل تفاصيل المسار</h2>
                            <InputField type={"text"}   id={"TrackEditNameInput"}  className={"chageFormInput"} name={"TrackEditName"} placeholder={"اسم المسار"} value={title}/>
                            <InputField type={"text"}   id={"TrackEditDescriptionInput"}  className={"chageFormInput"} name={"TrackEditDescription"} placeholder={'شرح المسار'} value={description}/>
                            <InputField type={"number"} id={"TrackEditLecturesInput"}  className={"chageFormInput"} name={"TrackEditLectures"} placeholder={'عدد المحاضرات'} value={lectureCount}/>
                            <DropDownInput id={"trackEditDayInput"} classList={"chageFormInput"} placeholder={WeeklyDay} >
                                <option>السبت</option>
                                <option>الأحد</option>
                                <option>الاثنين</option>
                                <option>الثلاثاء</option>
                                <option>الأربعاء</option>
                                <option>الخميس</option>
                                <option>الجمعة</option>
                            </DropDownInput>
                            <InputField type={"time"}   id={"trackEditLectureTime"}  className={"chageFormInput"} name={"trackEditLectureTime"} placeholder={"المعاد المتوقع للمحاضرات"} value={dayTime} />
                            <InputField type={"date"}   id={"trackEditDeadlineInput"}  className={"chageFormInput"} name={"TrackEditDeadline"} placeholder={"اخر معاد للتقديم"} value={deadline}/>
                            <InputField type={"text"}   id={"TrackEditImageLinkInput"}  className={"chageFormInput"} name={"TrackEditImageLink"} placeholder={"رابط الصورة"} value={imgLink} />

                            <button onClick={SubmitTrackEdit}>تعديل</button>
                        </div>
                    </div>
            </div>
            <div className="WarningContainer ArchiveWarningContainer" key={`EditFormTrack${id}`}>
                    <div className="">
                        <div className="backgroundBlock" onClick={closeArchiveWarning}></div>
                        <div className="WarningBox">
                            <h2>ارشفة المسار</h2>
                            <p className="redAlert">هذا الاجراء خطير حيث انه لا رجعة منه, بالرغم من هذا يمكنك الاطلاع علي التفاصيل العامه للمسار بعد الارشفة</p>
                            <button onClick={ArchiveTrack}>ارشفة</button>
                        </div>
                    </div>
            </div>
            <div className="WarningContainer DeleteWarningContainer" key={`EditFormTrack${id}`}>
                    <div className="">
                        <div className="backgroundBlock" onClick={closeDeleteWarning}></div>
                        <div className="WarningBox">
                            <h2>حذف المسار</h2>
                            <p className="redAlert">هذا الاجراء خطير حيث انه لا رجعة منه مطلقا, سيتم حذف سجلات الغياب و المصاريف و المواد العلمية</p>

                            <button onClick={DeleteTrack}>حذف</button>
                        </div>
                    </div>
            </div>
            <div className="row">
                <div className="TrackOptionControl">
                    <div className="dotsButton">
                        <FontAwesomeIcon icon="fa-solid fa-gear" />
                    </div>
                    <div className="TrackOptionsButtons">
                        <div className="optionButton" onClick={openForm}>تعديل التفاصيل</div>
                        <div className="optionButton redAlert" onClick={openArchiveWarning}>ارشفة المسار</div>
                        <div className="optionButton redAlert" onClick={openDeleteWarning}>حذف المسار</div>
                    </div>
                </div>
                <div className="right trackInfo">
                    <h2 className="trackTitle">مسار {title}</h2>
                    <p className="trackDescription">{description}</p>
                </div>
                <div className="left">
                    {/* <img className={"trackCover"} src={imgLink}/> */}
                    <img className={"trackCover"} src={defualtImage}/>
                    
                    <div>
                        <span>مدة الدراسة: {lectureCount} محاضرة</span>
                        <br/>
                        <span>عدد الطلاب: {studentsCount}</span>
                    </div>
                </div>
            </div>
        
            {passed<=0?
                <div className="actionButtons">
                        <button className="FormResponsesButton close" onClick={GoToFormResponses}>نتائج الاستمارة</button>
                    </div>
                :
                <div>
                    {passed<=0?"":
                                    <>
                                        <div className="InfoBox InfoBoxGray inviteLink row">
                                            <FontAwesomeIcon icon="fa-solid fa-link" />
                                            <input className="link" value={public_variables.fronend_url+public_variables.trackInfo+enrollmentToken}/>
                                            <div className="copyIcon" onClick={copyToClipboard}>
                                                <FontAwesomeIcon icon="fa-solid fa-copy"/>
                                                <span className="linkType">تسجيل المقبولين</span>
                                            </div>
                                            <span className="copiedText hide">تم النسخ</span>

                                        </div>
                                        <div className="InfoBox InfoBoxGray inviteLink row">
                                            <FontAwesomeIcon icon="fa-solid fa-link" />
                                            <input className="link" value={public_variables.fronend_url+public_variables.user_registraion+enrollmentToken}/>
                                            <div className="copyIcon" onClick={copyToClipboard}>
                                                <FontAwesomeIcon icon="fa-solid fa-copy"/>
                                                <span className="linkType">الاستمارة</span>
                                            </div>
                                            <span className="copiedText hide">تم النسخ</span>

                                        </div>
                                    </>}
                    <div className="actionButtons">
                        <button className="enrollButton close" onClick={closeEnrollment}>غلق باب التسجيل</button>
                        <button className="FormResponsesButton close" onClick={GoToFormResponses}>نتائج الاستمارة</button>
                    </div>
                </div>
            }
    </div>
  );
}

export default SuperTrackBox;
